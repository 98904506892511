import React, { useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import {
    createTheme,
    Container,
    CssBaseline,
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    Divider,
    Alert,
    AlertTitle,
    AlertColor,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useDispatch } from 'react-redux';
import axios from '../api/axiosPublic';
import { useResetPasswordConfirmMutation } from '../features/api/publicApiSlice';

const theme = createTheme();

const ResetPasswordConfirm = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token, uid } = useParams();
    const [alert, setAlert] = useState(undefined);
    const [resetPasswordConfirm, result] = useResetPasswordConfirmMutation();
    const handleSubmit = (event) => {
        event.preventDefault();
        setAlert(undefined);
        const data = new FormData(event.currentTarget);
        resetPasswordConfirm({
            new_password: data.get('password'),
            re_new_password: data.get('re_password'),
            token,
            uid,
        })
            .then((res) => {
                if (res.error) {
                    const message = res?.error?.data ? res.error.data : undefined;
                    setAlert({
                        title: 'Something went wrong',
                        severity: 'error',
                        message,
                    });
                } else {
                    setAlert({
                        title: 'Password changed successfully',
                        message: 'Redirecting to login page....',
                        severity: 'success',
                    });
                }
            })
            .catch((err) => {
                setAlert({
                    title: 'Something went wrong',
                    severity: 'error',
                });
            });
    };
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '100vh',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ marginRight: 'auto', marginBottom: 1 }}>
                        Reset Password
                    </Typography>
                    <Paper
                        sx={{
                            paddingTop: 3,
                            paddingRight: 10,
                            paddingBottom: 3,
                            paddingLeft: 3,
                            mt: 2,
                        }}
                    >
                        {alert && (
                            <Alert severity={alert.severity}>
                                {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
                                {alert.severity === 'success' && (
                                    <>
                                        <Link
                                            href="/login"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/login');
                                            }}
                                        >
                                            Click here
                                        </Link>{' '}
                                        to go login page.
                                    </>
                                )}
                                {alert.message && (
                                    <ul>
                                        {alert.message.new_password?.map((item) => {
                                            return <li key={item}>{item}</li>;
                                        })}
                                        {alert.message.non_field_errors?.map((item) => {
                                            return <li key={item}>{item}</li>;
                                        })}
                                        {alert.message.uid?.map((item) => {
                                            return <li key={item}>{item}</li>;
                                        })}
                                        {alert.message.token && <li>{'Invalid or Expired Url'}</li>}
                                    </ul>
                                )}
                            </Alert>
                        )}
                        <Typography>
                            Enter your new password below. <br /> Hint: The password should be at least twelve
                            characters long. To make it stronger, use upper and lower case letters, numbers, and symbols
                            like !"? $ % ^ &.
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, mb: 3 }}>
                            <TextField
                                margin="normal"
                                required
                                id="password"
                                label="Password"
                                name="password"
                                type="password"
                                autoFocus
                                fullWidth
                            />
                            <TextField
                                margin="normal"
                                required
                                type="password"
                                id="re_password"
                                label="Confirm Password"
                                name="re_password"
                                fullWidth
                            />
                            <Button type="submit" variant="outlined" sx={{ mt: 3, mb: 2 }}>
                                Reset Password
                            </Button>
                        </Box>
                        <Divider />
                        <Grid container>
                            <Grid item sm sx={{ mt: 1, fontSize: 15 }}>
                                <Link
                                    href="/login"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/login');
                                    }}
                                    variant="inherit"
                                >
                                    Remember password?
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default ResetPasswordConfirm;
