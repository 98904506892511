import { useState, useEffect, useMemo } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    Grid,
    InputLabel,
    Modal,
    TextField,
} from '@mui/material';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useGetCinBatchDetailsQuery } from '../features/api/apiSlice';
import Scrollbar from '../components/scrollbar';
import { useGetBatchCinDataMutation } from '../features/api/deepApiSlice';
import { useTable, TableNoData, TableHeadCustom, TablePaginationCustom } from '../components/table';
import { modalStyles as style, TextFieldStyles } from '../utils/cssStyles';
import BatchTableRow from '../components/table/batch-table-rows';

const TABLE_HEAD = [
    { id: 'slNo', label: 'Sl.No' },
    { id: 'date&time', label: 'Date&Time' },
    { id: 'referenceID', label: 'ReferenceID' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action' },
    { id: '' },
];

const BatchCINVerification = () => {
    const [BatchBavData, setBatchBavData] = useState([]);

    const Data = useGetCinBatchDetailsQuery();

    const refreshTableData = () => {
        Data.refetch();
    };

    useEffect(() => {
        if (Data.data) {
            setBatchBavData(Data?.data);
        }
    }, [Data?.data]);

    const table = useTable({ defaultOrderBy: 'createDate' });

    const [dataFiltered, setDataFiltered] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const batchData = useMemo(() => {
        return BatchBavData.map((item) => ({
            date: new Date(item.created_at).toLocaleString(),
            time: new Date(item.created_at).toLocaleTimeString('en-IN', { hour12: false }),
            referenceID: item.transaction_id,
            status: item.status,
            downloadlink: item.batch_output_file_url,
        }));
    }, [BatchBavData]);

    useEffect(() => {
        setDataFiltered(batchData);
    }, [batchData]);

    const notFound = !dataFiltered?.length;

    const [modalOpen, setModalOpen] = useState(false);
    const [getBatchCinData, { data, isLoading, isError, error }] = useGetBatchCinDataMutation();

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validate: (values) => {
            return null;
        },
        onSubmit: async (values) => {
            await getBatchCinData({
                batch_file: await values.picture1,
            }).unwrap();
            setModalOpen(false);
            refreshTableData();
        },
    });

    useEffect(() => {
        if (modalOpen && data) {
            enqueueSnackbar(
                data?.code === 201 ? 'File Uploaded Successfully!' : 'Something went wrong. Please Reupload!'
            );
        }
    }, [modalOpen, data]);
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{ ...style, width: '50%' }}
                    component="form"
                    autoComplete="on"
                    noValidate={false}
                    onSubmit={formik.handleSubmit}
                >
                    <Card>
                        <CardHeader
                            title="Bulk CIN Verification"
                            subheader="Verify CIN Numbers in bulk and onboard verified customers."
                        />
                        <CardContent>
                            <Grid
                                container
                                item
                                xs={12}
                                mb={2}
                                width={'100vw'}
                                spacing={2}
                                alignItems="flex-start"
                                flexDirection="column"
                            >
                                <Grid item>
                                    <InputLabel>Upload Batch File</InputLabel>
                                    <TextField
                                        InputProps={{
                                            shrink: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        size="small"
                                        type="file"
                                        name="picture1"
                                        onChange={(e) => {
                                            formik.setFieldValue('picture1', e.currentTarget.files[0]);
                                        }}
                                        onBlur={formik.handleBlur}
                                        error={formik.errors.picture1 !== undefined}
                                        helperText={formik.errors.picture1}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        disabled={!formik.isValid || isLoading}
                                        color="secondary"
                                        variant="outlined"
                                        type="submit"
                                    >
                                        {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                                    </Button>
                                </Grid>

                                <Grid item>
                                    {isError && (
                                        <Alert severity="error">
                                            <AlertTitle>
                                                {error?.data?.message || 'Something went wrong, please try again.'}
                                            </AlertTitle>
                                        </Alert>
                                    )}
                                </Grid>

                                <Grid item>
                                    <b style={{ color: 'red' }}>*</b>
                                    <i>Only supports .csv and .xlsx formats.</i>
                                </Grid>

                                <Grid item>
                                    <span>
                                        <a
                                            href="https://bulk-data-processing.s3.ap-south-1.amazonaws.com/sample-file-cin-batch.csv"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <b style={{ color: 'blue' }}>Here</b>
                                        </a>
                                        <span style={{ paddingLeft: '7px' }}>
                                            is the link to a sample batch file to upload.
                                        </span>
                                    </span>
                                </Grid>

                                <Grid container marginTop={4} justifyContent="center">
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setModalOpen(false);
                                        }}
                                        color="secondary"
                                        sx={{ padding: 0.5 }}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>

            <Grid container style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <Grid item>
                    <CardHeader
                        title="Bulk CIN Verification"
                        subheader="Verify CIN Numbers in bulk and onboard verified customers."
                    />
                </Grid>

                <Grid item style={{ textAlign: 'right', paddingRight: '5%' }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setModalOpen(true);
                        }}
                        color="secondary"
                        sx={{ padding: 1, marginTop: '40px' }}
                    >
                        Upload Batch File
                    </Button>
                </Grid>
            </Grid>

            {dataFiltered !== undefined && dataFiltered.length > 0 ? (
                <Container style={{ marginTop: '25px', maxWidth: '100%' }}>
                    <Card>
                        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                            <Scrollbar>
                                <Table>
                                    <TableHeadCustom headLabel={TABLE_HEAD} />

                                    <TableBody>
                                        {dataFiltered
                                            ?.slice(
                                                table.page * table.rowsPerPage,
                                                table.page * table.rowsPerPage + table.rowsPerPage
                                            )
                                            ?.map((row, index) => (
                                                <BatchTableRow
                                                    key={index + 1}
                                                    slno={index + 1}
                                                    row={row}
                                                    refreshTableData={refreshTableData}
                                                />
                                            ))}
                                    </TableBody>
                                </Table>
                            </Scrollbar>
                        </TableContainer>
                        <TablePaginationCustom
                            count={dataFiltered?.length}
                            page={table.page}
                            rowsPerPage={table.rowsPerPage}
                            onPageChange={table.onChangePage}
                            onRowsPerPageChange={table.onChangeRowsPerPage}
                            //
                            dense={table.dense}
                            onChangeDense={table.onChangeDense}
                        />
                    </Card>
                </Container>
            ) : (
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800, marginTop: 3 }}>
                    <TableHeadCustom headLabel={TABLE_HEAD} />
                    <TableNoData notFound={notFound} />
                </Table>
            )}
        </>
    );
};

export default BatchCINVerification;
