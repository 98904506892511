import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetUdyogAadhaarRequestIdMutation, useGetUdyogDataMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const delay = (time) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

const KycUdyog = () => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [getUdyogAadhaarRequestId, { isLoading, isError, error }] = useGetUdyogAadhaarRequestIdMutation();
    const [
        getUdyogAadhaarData,
        {
            data,
            isLoading: dataIsLoading,
            isFetching: dataIsFetching,
            isError: dataIsError,
            isSuccess: dataIsSuccess,
            error: dataError,
        },
    ] = useGetUdyogDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            setLoading(true);
            const requestData = await getUdyogAadhaarRequestId({ udyog_aadhaar_number: values.udyog }).unwrap();
            const getData = await fetch_data(requestData.request_id);
            setLoading(false);
            if (getData?.status === 'completed') setModalOpen(true);
        },
    });
    const fetch_data = async (request_id) => {
        const getData = await getUdyogAadhaarData({ request_id }).unwrap();
        if (getData?.status === 'in_progress') {
            await delay(2000);
            return fetch_data(request_id);
        }
        return getData;
    };

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={2}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Udyog Details &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Enterprise Name
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.enterprise_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Enterprise Type
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.enterprise_type || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                DIC Name
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.dic_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Major Activity
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.major_activity || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Social Category
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.social_category || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Applied Date
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.applied_date || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Modified Date
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.modified_date || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Expiry Date
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.expiry_date || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Commencement Date
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.commencement_date || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Official Address
                            </Grid>
                            <Grid item>
                                {(() => {
                                    const off_addr = data?.result?.source_output?.address_details[0]?.address_1;
                                    return off_addr
                                        ? [
                                              off_addr?.door,
                                              off_addr?.block,
                                              off_addr?.name_of_premises,
                                              off_addr?.road,
                                              off_addr?.city,
                                              off_addr?.state,
                                              off_addr?.pin,
                                          ].join(' ')
                                        : '-';
                                })()}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Udyog Aadhaar Verification"
                        subheader="By taking in the Udyog Aadhaar Id and receiving information pertaining to the Udyog Aadhaar Id, verify Udyog Aadhaar Ids."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Udyog Aadhaar Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Enter 12 Digit UAM No."
                                    size="small"
                                    type="text"
                                    name="udyog"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || loading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {loading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {data?.status === 'failed' && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {data?.message || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default KycUdyog;
