import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import CancelIconOutlined from '@mui/icons-material/CancelOutlined';
import { useGetAadhaarMaskingDataMutation } from '../features/api/deepApiSlice';
import { modalStyles as style, TextFieldStyles } from '../utils/cssStyles';
import { toBase64 } from '../utils/util';

const AadhaarMasking = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getAadhaarMaskingData, { data, isLoading, isFetching, isError, isSuccess, error }] =
        useGetAadhaarMaskingDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            await getAadhaarMaskingData({
                document: await toBase64(values.image),
            }).unwrap();
            setModalOpen(true);
        },
    });

    const download_masked_document = (img_base64) => {
        const linkSource = `data:image/png;base64,${img_base64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        // Generating a unique name with a 4-digit hash
        const hash = Math.floor(1000 + Math.random() * 9000);
        const uniqueName = `masked_aadhaar_${hash}`;
        downloadLink.download = uniqueName;
        downloadLink.click();
    };

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        {isSuccess && data?.data?.masked_document ? (
                            <>
                                Aadhaar Masking Successful &nbsp;
                                <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                            </>
                        ) : (
                            <>
                                Aadhaar Masking Failed &nbsp;
                                <CancelIconOutlined fontSize="small" htmlColor="red" />
                            </>
                        )}
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="column" my={0.5}>
                            {isSuccess && data?.data?.masked_document ? (
                                <>
                                    <Button
                                        variant="contained"
                                        onClick={() => download_masked_document(data?.data?.masked_document)}
                                        color="secondary"
                                        sx={{
                                            padding: 1.5,
                                            color: 'white',
                                            backgroundColor: 'green',
                                            '&:hover': {
                                                backgroundColor: 'darkgreen',
                                            },
                                        }}
                                    >
                                        Download Masked Aadhaar
                                    </Button>
                                </>
                            ) : (
                                <Grid flexDirection="column" my={0.5} color="red">
                                    Invalid Document!
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Aadhaar Masking"
                        subheader="The Aadhaar masking API takes base64 or publically accessible URL of the aadhaar document as input and returns back the masked Aadhaar Document in response."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Aadhaar Document</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: TextFieldStyles,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    type="file"
                                    name="image"
                                    onChange={(e) => {
                                        formik.setFieldValue('image', e.target.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.image !== undefined}
                                    helperText={formik.errors.image}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default AadhaarMasking;
