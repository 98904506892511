import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Button, Alert, AlertTitle } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
// components
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import Iconify from '../../../components/iconify';
import { setAuth } from '../../../features/auth/authSlice';
import { setRefreshToken } from '../../../api/refreshToken';
import { useLoginMutation, useGenerateCaptchaMutation } from '../../../features/api/publicApiSlice';
import { useUserMutation } from '../../../features/api/apiSlice';
// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const [login, { error, isLoading, isError }] = useLoginMutation();
    const [getUser, { isLoading: isUserLoading }] = useUserMutation();

    const [captcha, setCaptcha] = useState('');
    const [guestToken, setGuestToken] = useState('');
    const [generateCaptcha] = useGenerateCaptchaMutation();

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validate: (values) => {
            return null;
        },
        onSubmit: async (values) => {
            try {
                const loginData = await login({
                    email: values.email,
                    password: values.password,
                    guest_token: guestToken,
                    captcha: values.captchaInput,
                }).unwrap();
                setRefreshToken(loginData.refresh);
                const user = await getUser().unwrap();
                dispatch(setAuth({ user, access_token: loginData.access, authenticated: true }));
                if (user) navigate('/dashboard/app');
            } catch (error) {
                console.error('Login failed:', error);
            }
        },
    });

    const fetchCaptcha = async (token = null) => {
        try {
            const captchaData = await generateCaptcha(token || null).unwrap();
            setCaptcha(`data:image/png;base64,${captchaData.captcha_base64}`);
            setGuestToken(captchaData.guest_token);
        } catch (err) {
            console.error('Failed to fetch CAPTCHA', error);
        }
    };

    const refreshCaptcha = () => {
        fetchCaptcha(guestToken);
    };

    useEffect(() => {
        fetchCaptcha();
    }, []);

    return (
        <>
            <Box my={2}>
                {isError && (
                    <Alert severity="error">
                        {error?.data?.error ? (
                            <AlertTitle>{error.data.error || 'Something went wrong, try again.'}</AlertTitle>
                        ) : (
                            <AlertTitle>
                                {error?.data?.detail || 'Something went wrong, try reloading page.'}
                            </AlertTitle>
                        )}
                        {error?.data?.error
                            ? null
                            : "Check email if you haven't activated account or resend the activation link."}
                    </Alert>
                )}
            </Box>

            <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
                <Stack spacing={3}>
                    <TextField
                        required
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="email"
                        label="Email address"
                    />
                    <TextField
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box>
                        <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                            <TextField
                                name="captchaInput"
                                label="Enter CAPTCHA"
                                onChange={formik.handleChange}
                                required
                                sx={{ flexGrow: 1 }}
                                inputProps={{ maxLength: 5 }}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={captcha}
                                    alt="CAPTCHA"
                                    style={{
                                        width: '130px',
                                        height: '55px',
                                        objectFit: 'cover',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                />
                                <IconButton
                                    onClick={refreshCaptcha}
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        '&:hover': { backgroundColor: '#e0e0e0' },
                                    }}
                                    aria-label="Refresh CAPTCHA"
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    {/* <Checkbox name="remember" label="Remember me" /> */}
                    <Box flexGrow={1} />
                    <Link href="/reset_password" variant="subtitle2" underline="hover">
                        Reset password?
                    </Link>
                </Stack>

                <Button
                    disabled={isLoading || isUserLoading}
                    fullWidth
                    color="secondary"
                    type="submit"
                    size="large"
                    variant="contained"
                >
                    {isLoading || isUserLoading ? <CircularProgress size={'1.5rem'} /> : 'Login'}
                </Button>
            </Box>
        </>
    );
}
