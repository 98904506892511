import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetChequDataMutation } from '../features/api/deepApiSlice';
import '../theme/css/fileInput.css';
import { modalStyles as style, TextFieldStyles } from '../utils/cssStyles';
import { toBase64 } from '../utils/util';
import { validate_supported_file_formats } from '../utils/validate_supported_file_formats_ocr';

const BankChequeOcr = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getPanData, { data, isLoading, isFetching, isError, isSuccess, error }] = useGetChequDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validate: (values) => {
            return validate_supported_file_formats(values);
        },
        onSubmit: async (values) => {
            await getPanData({ document1: await toBase64(values.picture1), name: values.name }).unwrap();
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Bank Cheque Details &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Bank Name
                            </Grid>
                            <Grid item>{data?.data?.bank_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Account Number
                            </Grid>
                            <Grid item>{data?.data?.account_no || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                IFSC Code
                            </Grid>
                            <Grid item>{data?.data?.ifsc_code || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Bank A/C Holder Name
                            </Grid>
                            <Grid item>{data?.data?.account_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Bank Address
                            </Grid>
                            <Grid item>{data?.data?.bank_address || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Cheque Number
                            </Grid>
                            <Grid item>{data?.data?.micr_cheque_number || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                MICR Code
                            </Grid>
                            <Grid item>{data?.data?.micr_code || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Name Cleaned
                            </Grid>
                            <Grid item>{data?.data?.name_information?.name_cleaned || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Name Provided
                            </Grid>
                            <Grid item>{data?.data?.name_information?.name_provided || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Match Score
                            </Grid>
                            <Grid item>{data?.data?.name_information?.match_score || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Divider sx={{ width: '100%' }} flexItem />
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Digitisation Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Bank Cheque OCR"
                        subheader="Extract Bank Cheque details in real time to onboard legitimate and successful customers."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Upload Cheque</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: TextFieldStyles,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    type="file"
                                    name="picture1"
                                    onChange={(e) => {
                                        formik.setFieldValue('picture1', e.target.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.picture1 !== undefined}
                                    helperText={formik.errors.picture1}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Name(optional)</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: TextFieldStyles,
                                    }}
                                    placeholder="Name"
                                    size="small"
                                    type="text"
                                    name="name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item>
                                <b style={{ color: 'red' }}>*</b>
                                <i>Supports jpeg, jpg, png and pdf formats.</i>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default BankChequeOcr;
