import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetGstinOcrDataMutation } from '../features/api/deepApiSlice';
import '../theme/css/fileInput.css';
import { modalStyles as style, TextFieldStyles } from '../utils/cssStyles';
import { toBase64 } from '../utils/util';
import { validate_supported_file_formats } from '../utils/validate_supported_file_formats_ocr';

const GstinCertificateOcr = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getGstinOcrData, { data, isLoading, isFetching, isError, isSuccess, error }] = useGetGstinOcrDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validate: (values) => {
            return validate_supported_file_formats(values);
        },
        onSubmit: async (values) => {
            await getGstinOcrData({
                document1: await toBase64(values.picture1),
            }).unwrap();
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        GSTIN Certificate Details &nbsp;{' '}
                        <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                GSTIN
                            </Grid>
                            <Grid item>{data?.data?.gstin || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Address
                            </Grid>
                            <Grid item>{data?.data?.address || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Constitution of Business
                            </Grid>
                            <Grid item>{data?.data?.constitution_of_business || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of Liability
                            </Grid>
                            <Grid item>{data?.data?.date_of_liability || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Is Provisional
                            </Grid>
                            <Grid item>{data?.data?.is_provisional || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Legal Name
                            </Grid>
                            <Grid item>{data?.data?.legal_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Pan Number
                            </Grid>
                            <Grid item>{data?.data?.pan_number || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Trade Name
                            </Grid>
                            <Grid item>{data?.data?.trade_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Type of Registration
                            </Grid>
                            <Grid item>{data?.data?.type_of_registration || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Valid From
                            </Grid>
                            <Grid item>{data?.data?.valid_from || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Valid Upto
                            </Grid>
                            <Grid item>{data?.data?.valid_upto || '-'}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Digitisation Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="GSTIN Certificate OCR"
                        subheader="Extract GSTIN Certificate details in real time to onboard legitimate and successful customers."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Upload GSTIN Certificate(Front)</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: TextFieldStyles,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    type="file"
                                    name="picture1"
                                    onChange={(e) => {
                                        formik.setFieldValue('picture1', e.target.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.picture1 !== undefined}
                                    helperText={formik.errors.picture1}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item>
                                <b style={{ color: 'red' }}>*</b>
                                <i>Supports jpeg, jpg, png and pdf formats.</i>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default GstinCertificateOcr;
