import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

// ----------------------------------------------------------------------

export default function TableNoData({ notFound, sx }) {
    return (
        <TableRow>
            {notFound ? (
                <TableCell colSpan={12}>
                    <p>No Data</p>
                </TableCell>
            ) : (
                <TableCell colSpan={12} sx={{ p: 0 }} />
            )}
        </TableRow>
    );
}

TableNoData.propTypes = {
    notFound: PropTypes.bool,
    sx: PropTypes.object,
};
