import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
    Stack,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetMobileToUanMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const MobileToUan = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getMobileToUan, { data, isLoading, isFetching, isError, isSuccess, error }] = useGetMobileToUanMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            await getMobileToUan({
                mobile_number: values.mobile_number,
            }).unwrap();
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Mobile to Uan &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Stack direction="row" gap={2} my={0.5}>
                            <Typography item color="gray" sm={6}>
                                Uan
                            </Typography>
                            <Typography item>{data?.data?.uan_number || '-'}</Typography>
                        </Stack>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Mobile to Uan"
                        subheader="The API to fetch the UAN Number of the user linked with the phone number."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Mobile Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Mobile Number"
                                    size="small"
                                    type="text"
                                    name="mobile_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default MobileToUan;
