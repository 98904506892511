import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, CircularProgress } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    stroke_curve: PropTypes.string,
    y_axis_formatter: PropTypes.object,
};

export default function AppWebsiteVisits({
    title,
    subheader,
    chartLabels,
    chartData,
    stroke_curve,
    y_axis_formatter,
    ...other
}) {
    const chartOptions = useChart({
        chart: {
            type: 'bar',
            stacked: true,
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                stacked: true,
            },
        },
        fill: { opacity: 1 },
        labels: chartLabels,
        xaxis: { type: 'datetime' },
        tooltip: {
            shared: true,
            intersect: false,
            y: y_axis_formatter,
        },
        stroke: {
            width: 2,
        },
    });

    const isloading = !chartData || chartData.length === 0 || chartLabels.length === 0;

    return (
        <Card
            {...other}
            sx={{
                boxShadow: 10,
            }}
        >
            <CardHeader title={title} subheader={subheader} />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                {isloading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 364,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <ReactApexChart type="bar" series={chartData} options={chartOptions} height={364} />
                )}
            </Box>
        </Card>
    );
}
