import React from 'react';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, IconButton, Popover, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';

const FILTERS = [
    { name: 'Status', varName: 'status', type: 'number' },
    { name: 'Message', varName: 'message' },
    { name: 'Tran. Id', varName: 'transaction_id' },
];
export default function EnhancedTableToolbar({ setFilters }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const data = React.useRef({});
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const formik = useFormik({
        initialValues: data.current,
        enableReinitialize: true,
        validate: (values) => {
            const error = {};
            if (Number.isNaN(values.value)) {
                error.value = 'Please enter a valid status code.';
            }
            return error;
        },
        onSubmit: async (values) => {
            data.current = values;
            if (values.value === '' || values.value === null) {
                setFilters([]);
                return;
            }
            setFilters(values);
        },
    });
    const handleClose = () => {
        setAnchorEl(null);
        formik.submitForm();
    };
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                Usage
            </Typography>
            <Tooltip title="Filter list">
                <IconButton onClick={handleClick} aria-describedby="usage-data-grid-filter">
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
            <Popover
                id={'usage-data-grid-filter'}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid contaienr p={1}>
                    <Box component="form" flexDirection="column" onSubmit={formik.handleSubmit}>
                        {FILTERS.map((el) => {
                            return (
                                <Grid
                                    mb={2}
                                    key={el.varName}
                                    item
                                    flexDirection="row"
                                    display="flex"
                                    alignItems="flex-end"
                                >
                                    <TextField
                                        disabled
                                        value={`${el.name} :`}
                                        inputProps={{
                                            sx: { width: '100px', color: 'black !important' },
                                        }}
                                        // label="state"
                                        variant="standard"
                                    />
                                    <TextField
                                        id={el.varName}
                                        onChange={formik.handleChange}
                                        label="Value"
                                        placeholder="Value"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        type={el.type || 'text'}
                                        error={formik.errors.value}
                                        helperText={formik.errors.value || ''}
                                        inputProps={{
                                            name: el.varName,
                                            id: el.varName,
                                        }}
                                        variant="standard"
                                        required
                                        defaultValue={data.current[el.varName]}
                                    />
                                </Grid>
                            );
                        })}
                    </Box>
                </Grid>
            </Popover>
        </Toolbar>
    );
}
