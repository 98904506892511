import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../features/api/apiSlice';
import Dashboard from '../features/dashboard/dashboardSlice';
import Auth from '../features/auth/authSlice';
import { publicApiSlice } from '../features/api/publicApiSlice';
import { backOfficeApiSlice } from '../features/api/backOfficeApiSlice';
import { deepApiSlice } from '../features/api/deepApiSlice';

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        [publicApiSlice.reducerPath]: publicApiSlice.reducer,
        [backOfficeApiSlice.reducerPath]: backOfficeApiSlice.reducer,
        [deepApiSlice.reducerPath]: deepApiSlice.reducer,
        dashboard: Dashboard,
        auth: Auth,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({})
            .concat(publicApiSlice.middleware)
            .concat(apiSlice.middleware)
            .concat(backOfficeApiSlice.middleware)
            .concat(deepApiSlice.middleware),
});
