import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetVoterIdRequestIdMutation, useGetVoterIdDataMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const delay = (time) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

const KycVoterId = () => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [getVoterIdRequestId, { isLoading, isError, error }] = useGetVoterIdRequestIdMutation();
    const [
        getVoterIdData,
        {
            data,
            isLoading: dataIsLoading,
            isFetching: dataIsFetching,
            isError: dataIsError,
            isSuccess: dataIsSuccess,
            error: dataError,
        },
    ] = useGetVoterIdDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            setLoading(true);
            const requestData = await getVoterIdRequestId({
                epic_number: values.epic_number,
            }).unwrap();
            const getData = await fetch_data(requestData.request_id);
            setLoading(false);
            if (getData?.status === 'completed') setModalOpen(true);
        },
    });
    const fetch_data = async (request_id) => {
        const getData = await getVoterIdData({ request_id }).unwrap();
        if (getData?.status === 'in_progress') {
            await delay(2000);
            return fetch_data(request_id);
        }
        return getData;
    };
    useEffect(() => {
        console.log(data);
    }, [data]);

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={2}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Voter ID Details &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3} sx={{ overflowY: 'scroll', maxHeight: '70vh' }}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Name
                            </Grid>
                            <Grid item>{data?.result?.source_output?.name_on_card || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Gender
                            </Grid>
                            <Grid item>{data?.result?.source_output?.gender || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of Birth
                            </Grid>
                            <Grid item>{data?.result?.source_output?.date_of_birth || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                ID Number
                            </Grid>
                            <Grid item>{data?.result?.source_output?.id_number || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Status
                            </Grid>
                            <Grid item>{data?.result?.source_output?.status || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Source
                            </Grid>
                            <Grid item>{data?.result?.source_output?.source || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Last Update
                            </Grid>
                            <Grid item>{data?.result?.source_output?.last_update || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                House Number
                            </Grid>
                            <Grid item>{data?.result?.source_output?.house_no || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                District
                            </Grid>
                            <Grid item>{data?.result?.source_output?.district || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                State
                            </Grid>
                            <Grid item>{data?.result?.source_output?.state || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                State Code
                            </Grid>
                            <Grid item>{data?.result?.source_output?.st_code || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Part Number
                            </Grid>
                            <Grid item>{data?.result?.source_output?.part_no || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                AC Number
                            </Grid>
                            <Grid item>{data?.result?.source_output?.ac_no || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Section Number
                            </Grid>
                            <Grid item>{data?.result?.source_output?.section_no || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Polling Station (PS) Name
                            </Grid>
                            <Grid item>{data?.result?.source_output?.ps_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Polling Station (PS) LAT/LONG
                            </Grid>
                            <Grid item>{data?.result?.source_output?.ps_lat_long || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Relative Name
                            </Grid>
                            <Grid item>{data?.result?.source_output?.rln_name || '-'}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Voter ID Verification"
                        subheader="The Voter Id Verification API instantly verifies details of a voter id number by confirming them from the Government database."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>EPIC Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="EPIC Number"
                                    size="small"
                                    type="text"
                                    name="epic_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {(data?.status === 'failed' || isError) && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {data?.message ||
                                                error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default KycVoterId;
