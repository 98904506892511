import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';
import ReportsFilter from '../sections/@dashboard/reports/ReportsGenerate';

const Reports = () => {
    return (
        <>
            <Card>
                <CardHeader title="Reports" subheader="Get it right in your Email." />
                <CardContent>
                    <ReportsFilter />
                </CardContent>
            </Card>
        </>
    );
};

export default Reports;
