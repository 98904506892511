import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
// @mui
import { Box, Divider, Collapse, List, ListItemButton, ListItemText } from '@mui/material';

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { getSidebarData } from '.';

// ----------------------------------------------------------------------
NavSection.propTypes = {
    data: PropTypes.array,
};

const isValidUrl = (urlString) => {
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
};

export default function NavSection({ ...other }) {
    const location = useLocation();
    const [data, setData] = useState([]);
    const { user } = useSelector((state) => state.auth.auth);

    useEffect(() => {
        let key = 'default';
        if (location.pathname.includes('verification')) {
            key = 'verification';
        } else if (location.pathname.includes('back-office')) {
            key = 'back-office';
        }
        setData(getSidebarData({ user, key }));
    }, [user, location]);

    let categoryIndex = 0;

    return (
        <Box {...other}>
            <List disablePadding sx={{ p: 1 }}>
                {data.map((item, index) => {
                    if (item?.type === 'divider') {
                        return <Divider key={index} sx={item?.sx} />;
                    }

                    if (item?.type === 'category') {
                        const isFirstCategory = categoryIndex === 0;
                        categoryIndex += 1;

                        return <NavCategory key={index} category={item} isFirstCategory={isFirstCategory} />;
                    }

                    return item.render && <NavItem key={index} item={item} />;
                })}
            </List>
        </Box>
    );
}

function NavCategory({ category, isFirstCategory }) {
    const [open, setOpen] = useState(isFirstCategory);
    const location = useLocation();

    useEffect(() => {
        const isCategoryOpen = category.items.some((item) => location.pathname.includes(item.path));
        setOpen(isCategoryOpen);
    }, [category.items, location.pathname]);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemText primary={category.title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {category?.items?.map((item, index) => (
                        <NavItem key={index} item={item} />
                    ))}
                </List>
            </Collapse>
        </>
    );
}

function NavItem({ item }) {
    const { title, path, icon, info } = item;

    return (
        <StyledNavItem
            component={RouterLink}
            to={path}
            target={isValidUrl(path) && '_blank'}
            sx={{
                mb: 0.5,
                border: '1px solid rgb(194, 194, 194,0.3);',
                '&.active': {
                    border: '1px solid black',
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                },
            }}
        >
            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
            <ListItemText disableTypography primary={title} />
            {/* Add global styles for info components */}
            {info && info}
        </StyledNavItem>
    );
}
