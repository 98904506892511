import { useDispatch } from 'react-redux';
import { getRefreshToken } from '../api/refreshToken';
import axios from '../api/axiosPublic';
import { logout, setAccessToken } from '../features/auth/authSlice';

const useRefreshToken = () => {
    const dispatch = useDispatch();
    const refresh = async () => {
        const refresh_token = getRefreshToken();
        return await new Promise((resolve, reject) => {
            if (refresh_token === null) {
                reject();
            } else {
                axios
                    .post('/auth/jwt/refresh/', {
                        refresh: `${getRefreshToken()}`,
                    })
                    .then((res) => {
                        dispatch(setAccessToken(res.data.access));
                        resolve(res.data.access);
                    })
                    .catch((err) => {
                        dispatch(logout());
                        reject(err?.response?.data);
                    });
            }
        });
    };
    return refresh;
};

export default useRefreshToken;
