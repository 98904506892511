import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetMobileNetworkDetailsMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const MobileNetworkDetails = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getMobileNetworkDetails, { data, isLoading, isFetching, isError, isSuccess, error }] =
        useGetMobileNetworkDetailsMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            await getMobileNetworkDetails({
                mobile_number: values.mobile_number,
            }).unwrap();
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Mobile to Network Details &nbsp;{' '}
                        <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Current Network Name
                            </Grid>
                            <Grid item>{data?.data?.currentNetworkName || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Current Network Region
                            </Grid>
                            <Grid item>{data?.data?.currentNetworkRegion || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Is Phone Reachable
                            </Grid>
                            <Grid item>
                                {data?.data?.isPhoneReachable === undefined || data?.data?.isPhoneReachable === null
                                    ? '-'
                                    : data?.data?.isPhoneReachable
                                    ? 'Yes'
                                    : 'No'}
                            </Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Is Valid PhoneNumber
                            </Grid>
                            <Grid item>
                                {data?.data?.isValidPhoneNumber === undefined || data?.data?.isValidPhoneNumber === null
                                    ? '-'
                                    : data?.data?.isValidPhoneNumber
                                    ? 'Yes'
                                    : 'No'}
                            </Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Number Has Porting History
                            </Grid>
                            <Grid item>
                                {data?.data?.numberHasPortingHistory === undefined ||
                                data?.data?.numberHasPortingHistory === null
                                    ? '-'
                                    : data?.data?.numberHasPortingHistory
                                    ? 'Yes'
                                    : 'No'}
                            </Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Current Network Country CodeIso2
                            </Grid>
                            <Grid item>{data?.data?.currentNetworkcountryCodeIso2 || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                IMSI
                            </Grid>
                            <Grid item>{data?.data?.imsi || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Ported From Network Name
                            </Grid>
                            <Grid item>{data?.data?.portedFromNetworkName || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Number Billing Type
                            </Grid>
                            <Grid item>{data?.data?.numberBillingType || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Ported From Network Region
                            </Grid>
                            <Grid item>{data?.data?.portedFromNetworkRegion || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Ported From Network country CodeIso2
                            </Grid>
                            <Grid item>{data?.data?.portedFromNetworkcountryCodeIso2 || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Roaming Network Name
                            </Grid>
                            <Grid item>{data?.data?.roamingNetworkName || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Roaming Network Country CodeIso2
                            </Grid>
                            <Grid item>{data?.data?.roamingNetworkcountryCodeIso2 || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Roaming Network Region
                            </Grid>
                            <Grid item>{data?.data?.roamingNetworkRegion || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Roaming
                            </Grid>
                            <Grid item>
                                {data?.data?.roaming === undefined || data?.data?.roaming === null
                                    ? '-'
                                    : data?.data?.roaming
                                    ? 'Yes'
                                    : 'No'}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Mobile to Network Details"
                        subheader="The API to fetch the Mobile Network details of the phone number."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Mobile Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Mobile Number"
                                    size="small"
                                    type="text"
                                    name="mobile_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default MobileNetworkDetails;
