import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetAadharDataMutation, useGetAadharLogsQuery } from '../../features/api/backOfficeApiSlice';
import Modal from './Modal';
import '../../theme/css/fileInput.css';

const toCamelCase = (name) => {
    return name.replace(/-([a-z])/g, (g) => {
        return g[1].toUpperCase();
    });
};
const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result.split(',')[1]);
        };
        reader.onerror = (error) => reject(error);
    });

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const columns = [
    {
        field: 'created_at',
        headerName: 'Timestamp',
        type: 'date',
        editable: false,
        sortable: true,
        filterable: false,
        flex: 2,
        renderCell: ({ value }) => {
            return new Date(value).toLocaleString('en-US', { hour12: true });
        },
    },
    {
        field: 'aadhaar_name',
        headerName: 'Name',
        sortable: true,
        editable: false,
        flex: 2,
    },
    {
        field: 'name_provided',
        headerName: 'Name Provided',
        sortable: true,
        editable: false,
        flex: 2,
    },
    {
        field: 'name_match_score',
        headerName: 'Match Score',
        sortable: true,
        editable: false,
        flex: 1,
    },
    {
        field: 'aadhaar_number',
        headerName: 'Aadhaar Number',
        sortable: true,
        editable: false,
        flex: 1.5,
    },
    {
        field: 'aadhaar_dob',
        headerName: 'DOB',
        sortable: true,
        editable: false,
        flex: 1.5,
    },
    {
        field: 'aadhaar_gender',
        headerName: 'Gender',
        sortable: true,
        editable: false,
        flex: 1,
    },
    {
        field: 'aadhaar_address',
        headerName: 'Address',
        sortable: true,
        editable: false,
        flex: 2,
    },
];

const Aadhar = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [pic1Data, setPic1Data] = useState(null);
    const [pic2Data, setPic2Data] = useState(null);
    const [getPanData, { data, isLoading, isFetching, isError, isSuccess, error }] = useGetAadharDataMutation();
    const {
        data: logData,
        isLoading: logsIsLoading,
        isFetching: logsIsFetching,
        isError: logsIsError,
        isSuccess: logsIsSuccess,
        error: logsError,
    } = useGetAadharLogsQuery({ page });
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validate: (values) => {
            return null;
        },
        onSubmit: async (values) => {
            await getPanData({ document1: pic1Data, document2: pic2Data, name: values.name }).unwrap();
            console.log(data);
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                children={
                    <Box sx={{ ...style, width: 500 }}>
                        <CheckCircleOutlineTwoToneIcon
                            sx={{ margin: 'auto', display: 'block', fontSize: '4rem' }}
                            htmlColor="green"
                        />
                        <Box sx={{ margin: 'auto', display: 'block', width: 'fit-content' }}>
                            Aadhaar Card Details Processed Successfully
                        </Box>
                        {data?.data?.id_number && (
                            <>
                                <br />
                                <b>Id Number:</b> {data?.data?.id_number || 'None'}
                            </>
                        )}
                        {data?.data?.name_on_card && (
                            <>
                                <br />
                                <b>Name on Card:</b> {data?.data?.name_on_card || 'None'}
                            </>
                        )}
                        {data?.data?.name_match?.name_provided && (
                            <>
                                <br /> <b>Name Provided: </b>
                                {data?.data?.name_match?.name_provided}
                            </>
                        )}
                        {data?.data?.name_match?.match_score && (
                            <>
                                <br />
                                <b>Match Score: </b>
                                {data?.data?.name_match?.match_score}
                            </>
                        )}
                        {data?.data?.fathers_name && (
                            <>
                                <br />
                                <b>Father's Name:</b> {data?.data?.fathers_name || 'None'}
                            </>
                        )}
                        {data?.data?.date_of_birth && (
                            <>
                                <br />
                                <b>Date of Birth:</b> {data?.data?.date_of_birth || 'None'}
                            </>
                        )}
                        {data?.data?.year_of_birth && (
                            <>
                                <br />
                                <b>Year of Birth:</b> {data?.data?.year_of_birth || 'None'}
                            </>
                        )}
                        {data?.data?.gender && (
                            <>
                                <br />
                                <b>Gender:</b> {data?.data?.gender || 'None'}
                            </>
                        )}
                        {data?.data?.house_number && (
                            <>
                                <br />
                                <b>House Number:</b> {data?.data?.house_number || 'None'}
                            </>
                        )}
                        {data?.data?.street_address && (
                            <>
                                <br />
                                <b>Street Address:</b> {data?.data?.street_address || 'None'}
                            </>
                        )}
                        {data?.data?.district && (
                            <>
                                <br />
                                <b>District:</b> {data?.data?.district || 'None'}
                            </>
                        )}
                        {data?.data?.state && (
                            <>
                                <br />
                                <b>State:</b> {data?.data?.state || 'None'}
                            </>
                        )}
                        {data?.data?.pincode && (
                            <>
                                <br />
                                <b>PinCode:</b> {data?.data?.pincode || 'None'}
                            </>
                        )}
                        {data?.data?.address && (
                            <>
                                <br />
                                <b>Address:</b> {data?.data?.address || 'None'}
                            </>
                        )}
                    </Box>
                }
            />
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Back Office
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Aadhaar Card Verification"
                        subheader="Verify Aadhaar Details in real time to onboard legitimate and successful customers."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Upload Aadhaar Card(Front)</InputLabel>
                                <input
                                    required
                                    name="picture"
                                    accept="image/*"
                                    type="file"
                                    onChange={async (e) => {
                                        setPic1Data(await toBase64(e.target.files[0]));
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Upload Aadhaar Card(Back)(optional)</InputLabel>
                                <input
                                    name="picture"
                                    accept="image/*"
                                    type="file"
                                    onChange={async (e) => {
                                        setPic2Data(await toBase64(e.target.files[0]));
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Name(optional)</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Name"
                                    size="small"
                                    type="text"
                                    name="name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Card sx={{ mb: 2, mt: 5, boxShadow: 10 }}>
                <Box sx={{ height: '500px' }}>
                    <DataGrid
                        disableColumnFilter
                        pagination
                        pageSize={100}
                        onPageChange={(e) => {
                            setPage(e + 1);
                        }}
                        paginationMode="server"
                        rowCount={logData ? logData.count : 0}
                        columns={columns}
                        rows={logData?.results ? logData.results : []}
                        // components={{
                        //     Toolbar: GridToolbar,
                        //     Pagination: CustomPagination,
                        //     LoadingOverlay: LinearProgress,
                        //     NoRowsOverlay: CustomNoRowsOverlay,
                        // }}
                        // getRowId={(row) => {
                        //     return row.transactionid;
                        // }}
                        loading={logsIsFetching}
                    />
                </Box>
            </Card>
        </>
    );
};

export default Aadhar;
