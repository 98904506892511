import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetFaceMatchDataMutation } from '../features/api/deepApiSlice';
import { modalStyles as style, TextFieldStyles } from '../utils/cssStyles';

const Facematch = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getFaceMatchData, { data, isLoading, isFetching, isError, isSuccess, error }] =
        useGetFaceMatchDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            await getFaceMatchData({
                file_a: values.file_a,
                file_b: values.file_b,
            }).unwrap();
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Face Match Results &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Match
                            </Grid>
                            <Grid item>{data?.data?.match ? 'True' : 'False' || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Confidence
                            </Grid>
                            <Grid item>{data?.data?.confidence || '-'}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Face Match"
                        subheader="The Facematch API lets you compare faces present in two different documents, within seconds, performing a realtime AI-based face recognition and matching in the background."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Upload Face 1</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: TextFieldStyles,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    type="file"
                                    name="file_a"
                                    onChange={(e) => {
                                        formik.setFieldValue('file_a', e.target.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.file_a !== undefined}
                                    helperText={formik.errors.file_a}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Upload Face 2</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: TextFieldStyles,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    type="file"
                                    name="file_b"
                                    onChange={(e) => {
                                        formik.setFieldValue('file_b', e.target.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.file_b !== undefined}
                                    helperText={formik.errors.file_b}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default Facematch;
