import { useState, useEffect, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import Iconify from '../components/iconify';

const TABS = [
  {
    value: 'aadhaar-basic',
    label: 'Aadhaar Basic Verification',
    icon: <FingerprintIcon />,
  },
  {
    value: 'batch-aadhaar-basic',
    label: 'Batch',
    icon: <Iconify icon="mdi:file-document-outline" width={24} />,
  },
]

const Aadhaar = () => {
  const [activeTab, setActiveTab] = useState('aadhaar-basic');
  const [lastParam, setLastParam] = useState('');
  const navigate = useNavigate();

  const handleChangeTab = useCallback(
    (newValue) => {
      navigate(`/dashboard/verification/aadhaar/${newValue}`);
      setActiveTab(newValue);
    },
    [navigate]
  );

  useEffect(() => {
    const currentURL = window.location.href;
    const params = currentURL.split('/');
    const lastParamValue = params[params.length - 1];
    setLastParam(lastParamValue);
    setActiveTab(lastParamValue);
  })

  return (
    <Container
      sx={{
        maxWidth: {
          md: '100%',
        },
      }}
    >
      <Card
        sx={{
          mb: 3,
          height: 72,
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            left: 0,
            zIndex: 9,
            pl: 2,
            bgcolor: 'background.paper',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                md: 'flex-start',
              },
              alignContent: {
                md: 'center',
              },
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#0E46A3',
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              icon={tab.icon}
              label={tab.label}
              onClick={() => handleChangeTab(tab.value)}
              sx={{
                flexDirection: 'row',
                gap: '12px',
                color: '#637381',
                '&.Mui-selected': {
                  color: '#0E46A3',
                  fontWeight: 'bold',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: 'black',
                },
                '&:hover': {
                  color: '#0E46A3',
                  opacity: 1,
                },
              }}
            />
          ))}
        </Tabs>
      </Card>
      <Outlet />
    </Container>
  );
};

export default Aadhaar;
