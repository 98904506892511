import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetBankAccountMutation, useGetBankAccountLogsQuery } from '../../features/api/backOfficeApiSlice';
import Modal from './Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const columns = [
    {
        field: 'created_at',
        headerName: 'Timestamp',
        type: 'date',
        editable: false,
        sortable: true,
        filterable: false,
        flex: 2,
        renderCell: ({ value }) => {
            return new Date(value).toLocaleString('en-US', { hour12: true });
        },
    },
    {
        field: 'account_number',
        headerName: 'Bank A/c No.',
        sortable: true,
        editable: false,
        flex: 1.5,
    },
    {
        field: 'ifsc',
        headerName: 'IFSC',
        sortable: true,
        editable: false,
        flex: 2,
    },
    // {
    //     field: 'message',
    //     headerName: 'Message',
    //     sortable: true,
    //     editable: false,
    //     flex: 2,
    //     renderCell: (params) => {
    //         return params.value || 'None';
    //     },
    // },
    {
        field: 'name',
        headerName: 'Name at Bank',
        sortable: true,
        editable: false,
        flex: 3,
    },
    {
        field: 'name_provided',
        headerName: 'Name Provided',
        sortable: true,
        editable: false,
        flex: 3,
    },
    {
        field: 'name_match_score',
        headerName: 'Match Score',
        sortable: true,
        editable: false,
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Status',
        type: 'number',
        sortable: true,
        editable: false,
        flex: 0,
        renderCell: (params) => {
            return params.value === 200 ? 'Success' : 'Failed';
        },
    },
];

const Bav = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [getBankAccount, { data, isLoading, isFetching, isError, isSuccess, error }] = useGetBankAccountMutation();
    const {
        data: logData,
        isLoading: logsIsLoading,
        isFetching: logsIsFetching,
        isError: logsIsError,
        isSuccess: logsIsSuccess,
        error: logsError,
    } = useGetBankAccountLogsQuery({ page });
    console.log(data);
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validate: (values) => {
            return null;
        },
        onSubmit: async (values) => {
            await getBankAccount({
                account_number: values.account_number,
                ifsc: values.ifsc,
                name: values.name,
            }).unwrap();
            console.log(data);
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                children={
                    <Box sx={{ ...style, width: 500 }}>
                        <CheckCircleOutlineTwoToneIcon
                            sx={{ margin: 'auto', display: 'block', fontSize: '4rem' }}
                            htmlColor="green"
                        />
                        <Box sx={{ margin: 'auto', display: 'block', width: 'fit-content' }}>
                            Bank Account details verified successfully.
                        </Box>
                        <br />
                        <b>Account exists:</b> Yes <br /> <b>Name at Bank:</b> {data && data.data.name_at_bank}
                        <br /> <b>Account Number:</b> {formik.values.account_number} <br /> <b>IFSC Code: </b>
                        {formik.values.ifsc}
                        {data?.data?.name_match?.name_provided && (
                            <>
                                <br /> <b>Name Provided: </b>
                                {data?.data?.name_match?.name_provided}
                            </>
                        )}
                        {data?.data?.name_match?.match_score && (
                            <>
                                <br />
                                <b>Match Score: </b>
                                {data?.data?.name_match?.match_score}
                            </>
                        )}
                    </Box>
                }
            />
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Back Office
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Bank Account Verification"
                        subheader="Verify Bank Account Details in real time to onboard legitimate and successful customers."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Account Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Account Number"
                                    size="small"
                                    type="text"
                                    name="account_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>IFSC Code</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="IFSC"
                                    size="small"
                                    type="text"
                                    name="ifsc"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Name(optional)</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Name"
                                    size="small"
                                    type="text"
                                    name="name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>{error.data.message}</AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Card sx={{ mb: 2, mt: 5, boxShadow: 10 }}>
                <Box sx={{ height: '500px' }}>
                    <DataGrid
                        disableColumnFilter
                        pagination
                        pageSize={100}
                        onPageChange={(e) => {
                            setPage(e + 1);
                        }}
                        paginationMode="server"
                        rowCount={logData ? logData.count : 0}
                        columns={columns}
                        rows={logData?.results ? logData.results : []}
                        // components={{
                        //     Toolbar: GridToolbar,
                        //     Pagination: CustomPagination,
                        //     LoadingOverlay: LinearProgress,
                        //     NoRowsOverlay: CustomNoRowsOverlay,
                        // }}
                        // getRowId={(row) => {
                        //     return row.transactionid;
                        // }}
                        loading={logsIsFetching}
                    />
                </Box>
            </Card>
        </>
    );
};

export default Bav;
