import { Grid, CardHeader, TableContainer, Table, TableHead, TableRow, TableBody, Tooltip, Zoom } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Loading from '../utils/Loading';
import { useGetCredentialsQuery } from '../features/api/apiSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function Credentials() {
    const { enqueueSnackbar } = useSnackbar();
    const { data, error, isLoading } = useGetCredentialsQuery();

    return (
        <>
            <Grid container textAlign="center" justifyContent="center" alignItems="left" flexDirection="column">
                <CardHeader
                    sx={{ marginBottom: 2, textAlign: 'left' }}
                    title="API Credentials"
                    subheader="Here are your API keys, keep them safe"
                />

                {isLoading || !data || error ? (
                    <Loading type="bars" color="gray" width={4} />
                ) : (
                    <Grid container flexDirection="column" p={2} maxWidth={'100vw'} overflow="auto">
                        <Grid item lg={12} sm={12}>
                            <TableContainer component={Paper} sx={{ boxShadow: 5 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Created at</StyledTableCell>
                                            <StyledTableCell>Client Id</StyledTableCell>
                                            <StyledTableCell>Client Secret (x-api-key)</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row) => (
                                            <StyledTableRow key={row.client_secret}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.created_at}
                                                </StyledTableCell>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    title="Click to copy"
                                                    placement="bottom-start"
                                                    sx={{ cursor: 'pointer' }}
                                                    arrow
                                                >
                                                    <StyledTableCell
                                                        onClick={() => {
                                                            window.navigator.clipboard.writeText(row.client_id);
                                                            enqueueSnackbar('Successfuly copied to clipboard.', {
                                                                variant: 'success',
                                                            });
                                                        }}
                                                    >
                                                        {row.client_id}
                                                    </StyledTableCell>
                                                </Tooltip>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    title="Click to copy"
                                                    placement="bottom-start"
                                                    sx={{ cursor: 'pointer' }}
                                                    arrow
                                                >
                                                    <StyledTableCell
                                                        onClick={() => {
                                                            window.navigator.clipboard.writeText(row.client_secret);
                                                            enqueueSnackbar('Successfuly copied to clipboard.', {
                                                                variant: 'success',
                                                            });
                                                        }}
                                                    >
                                                        {row.client_secret}
                                                    </StyledTableCell>
                                                </Tooltip>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{ color: row.disabled ? 'red' : 'green' }}
                                                >
                                                    {row.disabled ? 'Disabled' : 'Enabled'}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
}