import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  Modal,
  TextField,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { modalStyles as style } from '../utils/cssStyles';
import { useDigiLockerSessionMutation, useDigiLockerAccessTokenMutation, useGetEAadhaarDataMutation } from '../features/api/deepApiSlice';

const Digilocker = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const [digiLockerSession, { data: sessionData, isLoading: isSessionLoading }] = useDigiLockerSessionMutation();

  const [digiLockerAccessToken, { data: accessTokenData, isLoading: isAccessTokenLoading }] = useDigiLockerAccessTokenMutation();

  const [getEAadhaarData, { data: eAadhaarData, isLoading: isEAadhaarDataLoading }] = useGetEAadhaarDataMutation();

  const formik = useFormik({
    initialValues: {
      accessToken: '',
    },
    onSubmit: (values) => {
      handleCompleteDigilockerJourney(values.accessToken);
    },
  });

  const handleInitiateSession = async () => {
    try {
      const response = await digiLockerSession().unwrap();
      if (response?.code === 201) {
        const authorizationUrl = response.data?.authorization_url;
        setTransactionId(response.transaction_id);
        if (authorizationUrl) {
          window.open(authorizationUrl, '_blank');
        } else {
          setErrorMessage('Authorization URL is missing.');
        }
        
      }
    } catch (error) {
      setErrorMessage(
        error?.data?.message || error?.data?.detail || 'Failed to initiate session. Please try again.'
      );
    }
  };

  const handleCompleteDigilockerJourney = async (digilockerCode) => {
    if (!transactionId) return;
    try {
      const response = await digiLockerAccessToken({ transactionId, digilockerCode }).unwrap();
      if (response?.code === 200 && response?.sub_code === 'SUCCESS') {
        const response = await getEAadhaarData({ transactionId }).unwrap();
        if (response?.code === 200 && response?.sub_code === 'SUCCESS') {
          setModalOpen(true);
          setErrorMessage('');  
          setTransactionId('');
        }
      }
    } catch (error) {
      setErrorMessage(
        error?.data?.message || error?.data?.detail || 'Failed to get Aadhaar Data. Please try again.'
      );
      formik.resetForm();
      setTransactionId('');
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setErrorMessage('');
    setTransactionId('');
    formik.resetForm();
  };

  return (
    <>
      <Modal
        open={modalOpen}
      >
        <Box sx={{ ...style }}>
          <Box
            mb={3}
            alignItems="center"
            display="flex"
            sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
          >
            Digilocker E-Aadhaar fetched successfully &nbsp;
            <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
          </Box>
          <Grid container mb={3} sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
            <Grid container flexDirection="row" my={0.5}>
              <Grid item color="gray" sm={6}>
                Aadhaar number
              </Grid>
              <Grid item>{eAadhaarData?.data?.masked_uid || '-'}</Grid>
            </Grid>

            <Grid container flexDirection="row" my={0.5}>
              <Grid item color="gray" sm={6}>
                Name
              </Grid>
              <Grid item>{eAadhaarData?.data?.proof_of_identity?.name || '-'}</Grid>
            </Grid>

            <Grid container flexDirection="row" my={0.5}>
              <Grid item color="gray" sm={6}>
                Date of Birth
              </Grid>
              <Grid item>{eAadhaarData?.data?.proof_of_identity?.dob || '-'}</Grid>
            </Grid>

            <Grid container flexDirection="row" my={0.5}>
              <Grid item color="gray" sm={6}>
                Gender
              </Grid>
              <Grid item>{eAadhaarData?.data?.proof_of_identity?.gender || '-'}</Grid>
            </Grid>

            <Grid container flexDirection="row" my={0.5}>
              <Grid item color="gray" sm={6}>
                Guardian Name
              </Grid>
              <Grid item>{eAadhaarData?.data?.proof_of_address?.care_of || '-'}</Grid>
            </Grid>

            <Grid container flexDirection="row" my={0.5}>
              <Grid item color="gray" sm={6}>
                Address
              </Grid>
              {eAadhaarData?.data?.proof_of_address
                ? [
                  eAadhaarData.data.proof_of_address.house,
                  eAadhaarData.data.proof_of_address.street,  
                  eAadhaarData.data.proof_of_address.locality,
                  eAadhaarData.data.proof_of_address.district,
                  eAadhaarData.data.proof_of_address.state,
                  eAadhaarData.data.proof_of_address.country,
                  eAadhaarData.data.proof_of_address.pincode,
                ]
                  .filter(Boolean)
                  .join(', ')
                : '-'}
            </Grid>

            <Grid container flexDirection="row" my={0.5}>
              <Grid item color="gray" sm={6}>
                Photo
              </Grid>
              <Grid item>
                {eAadhaarData?.data?.photo ? (
                  <img
                    src={`data:image/jpeg;base64,${eAadhaarData.data.photo}`}
                    alt="Decoded from base64"
                    style={{ width: '160px', height: 'auto' }}
                  />
                ) : (
                  '-'
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Button
              variant="contained"
              onClick={handleCloseModal}
              color="secondary"
              sx={{ padding: '5px 10px', boxShadow: 2 }}
            >
              Close
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
      <Box component="form" autoComplete="on" onSubmit={formik.handleSubmit}>
        <Card>
          <CardHeader
            title="Digilocker E-Aadhaar"
            subheader="Retrieve your user’s Aadhaar information with the DigiLocker API."
          />
          <CardContent>
            <Grid
              container
              item
              xs={12}
              mb={2}
              width={'100vw'}
              spacing={2}
              alignItems="flex-start"
              flexDirection="column"
            >
              {!transactionId && <Grid item>
                <Button
                  fullWidth
                  disabled={isSessionLoading}
                  variant="contained"
                  onClick={handleInitiateSession}
                  type="button"
                  color="primary"
                >
                  {isSessionLoading ? <CircularProgress size={'1.5rem'} /> : ' Verify Aadhaar'}
                </Button>
              </Grid>}

              {transactionId && (
                <>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Enter Digilocker Code"
                      variant="outlined"
                      value={formik.values.accessToken}
                      onChange={formik.handleChange}
                      name="accessToken"
                      required 
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      type="submit" 
                      disabled={!transactionId || isAccessTokenLoading || isEAadhaarDataLoading || !formik.values.accessToken}
                    >
                      {isEAadhaarDataLoading ? <CircularProgress size={'1.5rem'} /> : 'Verify'}
                    </Button>
                  </Grid>
                </>
              )}

              {errorMessage && (
                <Grid item>
                  <Alert severity="error">
                    <AlertTitle>
                      {errorMessage || 'Something went wrong, please try again.'}
                    </AlertTitle>
                  </Alert>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Digilocker;
