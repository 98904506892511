import { Grid, CardHeader, Box } from '@mui/material';
import React from 'react';
import ProductCard from './ProductCard';

export default function HomeProductList({ data, cardHead }) {
    return (
        <>
            <Grid container marginTop={2}>
                <Grid item xs={12}>
                    <CardHeader
                        sx={{ marginBottom: 4, textAlign: 'left' }}
                        title={cardHead?.title}
                        subheader={cardHead?.subheader}
                    />
                </Grid>

                <Grid
                    container
                    p={2}
                    sx={{
                        display: 'flex',
                        gap: {
                            sm: 4,
                            md: 3,
                            lg: 4,
                            xl: 4,
                        },
                        rowGap: {
                            xs: 4,
                            sm: 4,
                            md: 5,
                            lg: 7,
                            xl: 6,
                        },
                    }}
                >
                    {data?.map(
                        (product, index) =>
                            product.render && (
                                <Grid item key={index}>
                                    <ProductCard data={product} />
                                </Grid>
                            )
                    )}
                </Grid>
            </Grid>
        </>
    );
}
