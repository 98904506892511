import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetNameMatchDataMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const NameMatch = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getNameMatchData, { data, isLoading, isFetching, isError, isSuccess, error }] =
        useGetNameMatchDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            await getNameMatchData({
                name1: values.name1,
                name2: values.name2,
            }).unwrap();
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Name Matching Score &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Cleaned Name 1
                            </Grid>
                            <Grid item>{data?.data?.cleaned_name1 || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Cleaned Name 2
                            </Grid>
                            <Grid item>{data?.data?.cleaned_name2 || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Score
                            </Grid>
                            <Grid item>{data?.data?.score || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Token-wise Match Score
                            </Grid>
                            <Grid item color="gray" sm={6}>
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        First Name 1
                                    </Grid>
                                    <Grid item>{data?.data?.tokenwise_match[0]?.first_name1 || '-'}</Grid>
                                </Grid>
                                <Divider light sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        First Name 2
                                    </Grid>
                                    <Grid item>{data?.data?.tokenwise_match[0]?.first_name2 || '-'}</Grid>
                                </Grid>
                                <Divider light sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Score
                                    </Grid>
                                    <Grid item>{data?.data?.tokenwise_match[0]?.score || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Middle Name 1
                                    </Grid>
                                    <Grid item>{data?.data?.tokenwise_match[1]?.middle_name1 || '-'}</Grid>
                                </Grid>
                                <Divider light sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Middle Name 2
                                    </Grid>
                                    <Grid item>{data?.data?.tokenwise_match[1]?.middle_name2 || '-'}</Grid>
                                </Grid>
                                <Divider light sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Score
                                    </Grid>
                                    <Grid item>{data?.data?.tokenwise_match[1]?.score || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />

                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Last Name 1
                                    </Grid>
                                    <Grid item>{data?.data?.tokenwise_match[2]?.last_name1 || '-'}</Grid>
                                </Grid>
                                <Divider light sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Last Name 2
                                    </Grid>
                                    <Grid item>{data?.data?.tokenwise_match[2]?.last_name2 || '-'}</Grid>
                                </Grid>
                                <Divider light sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Score
                                    </Grid>
                                    <Grid item>{data?.data?.tokenwise_match[2]?.score || '-'}</Grid>
                                </Grid>
                                <Divider sx={{ width: '100%' }} flexItem />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Name Match"
                        subheader="This API is for finding occurrences of a substring from one name in another. It involves comparing patterns against the text to identify matches or similarities, and gives a score for that."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Name 1</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Name 1"
                                    size="small"
                                    type="text"
                                    name="name1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Name 2</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Name 2"
                                    size="small"
                                    type="text"
                                    name="name2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default NameMatch;
