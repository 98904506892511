import React, { useEffect, useState } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    CircularProgress,
    Typography,
} from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useFormik } from 'formik';
import { modalStyles as style } from '../utils/cssStyles';
import { useGenerateAadharOTPMutation, useVerifyAadharOTPMutation } from '../features/api/deepApiSlice';

const AadhaarEkyc = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [otpStage, setOtpStage] = useState(false);
    const [aadhaarDisabled, setAadhaarDisabled] = useState(false);
    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [resendDisabled, setResendDisabled] = useState(true);
    const [timer, setTimer] = useState(60);

    const [
        generateAadharOTP,
        { data: generatedOTPData, isLoading: isGeneratingOTP, isError: isGenerateOTPError, error: generateOTPError },
    ] = useGenerateAadharOTPMutation();

    const [
        verifyAadharOTP,
        { data: verifyOTPData, isLoading: isVerifyingOTP, isError: isVerifyOTPError, error: verifyOTPError },
    ] = useVerifyAadharOTPMutation();

    useEffect(() => {
        let countDown;
        if (otpStage && resendDisabled && timer > 0) {
            countDown = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(countDown);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        } else if (timer === 0) {
            setResendDisabled(false);
            clearInterval(countDown);
        }
        return () => {
            if (countDown) {
                clearInterval(countDown);
            }
        };
    }, [otpStage, resendDisabled, timer]);

    const formik = useFormik({
        initialValues: {
            aadhaar_number: '',
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const response = await generateAadharOTP({ aadhaar_number: values.aadhaar_number }).unwrap();
                if (response?.sub_code === 'SUCCESS') {
                    setOtpStage(true);
                    setAadhaarDisabled(true);
                    setErrorMessage('');
                }
            } catch (error) {
                setErrorMessage(
                    error?.data?.message ||
                        error?.data?.detail ||
                        'Failed to generate OTP. Please check the Aadhaar number.'
                );
            }
        },
    });

    const handleOtpSubmit = async () => {
        try {
            const response = await verifyAadharOTP({ otp }).unwrap();
            if (response?.sub_code === 'SUCCESS') {
                setTimer(60);
                setResendDisabled(true);
                setModalOpen(true);
                setErrorMessage('');
            }
        } catch (error) {
            setErrorMessage(error?.data?.message || error?.data?.detail || 'Failed to verify OTP. Please try again.');
        }
    };

    const handleResendOTP = async () => {
        setResendDisabled(true);
        setTimer(60);
        try {
            const response = await generateAadharOTP({ aadhaar_number: formik.values.aadhaar_number }).unwrap();
        } catch (error) {
            setErrorMessage(error?.data?.message || 'Failed to resend OTP. Please try again.');
            setResendDisabled(false);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setOtpStage(false);
        setAadhaarDisabled(false);
        setResendDisabled(true);
        setTimer(60);
        setOtp('');
        setErrorMessage('');
        formik.resetForm();
    };

    const generatedAt = parseInt(verifyOTPData?.data?.generatedAt, 10);
    const formattedTime = generatedAt
        ? new Date(generatedAt).toLocaleString('en-IN', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
          })
        : '-';

    const download_masked_document = (pdf_base64) => {
        const linkSource = `data:application/pdf;base64,${pdf_base64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        // Generating a unique name with a 4-digit hash
        const hash = Math.floor(1000 + Math.random() * 9000);
        const uniqueName = `masked_aadhaar_${hash}.pdf`;
        downloadLink.download = uniqueName;
        downloadLink.click();
    };

    return (
        <>
            <Modal open={modalOpen}>
                <Box sx={{ ...style }}>
                    <Box
                        mb={3}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Aadhaar eKYC Successful &nbsp;
                        <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3} sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Aadhaar number
                            </Grid>
                            <Grid item>{verifyOTPData?.data?.maskedNumber || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Name
                            </Grid>
                            <Grid item>{verifyOTPData?.data?.name || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of Birth
                            </Grid>
                            <Grid item>{verifyOTPData?.data?.dateOfBirth || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Gender
                            </Grid>
                            <Grid item>{verifyOTPData?.data?.gender || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Guardian Name
                            </Grid>
                            <Grid item>{verifyOTPData?.data?.address?.careOf || '-'}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Verified At
                            </Grid>
                            <Grid item>{formattedTime}</Grid>
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Address
                            </Grid>
                            {verifyOTPData?.data?.address
                                ? [
                                      verifyOTPData.data.address.house,
                                      verifyOTPData.data.address.street,
                                      verifyOTPData.data.address.landmark,
                                      verifyOTPData.data.address.postOffice,
                                      verifyOTPData.data.address.district,
                                      verifyOTPData.data.address.state,
                                      verifyOTPData.data.address.country,
                                      verifyOTPData.data.address.pin,
                                  ]
                                      .filter(Boolean)
                                      .join(', ')
                                : '-'}
                        </Grid>

                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Photo
                            </Grid>
                            <Grid item>
                                {verifyOTPData?.data?.photo ? (
                                    <img
                                        src={`data:image/jpeg;base64,${verifyOTPData.data.photo}`}
                                        alt="Decoded from base64"
                                        style={{ width: '160px', height: 'auto' }}
                                    />
                                ) : (
                                    '-'
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" spacing={2} mt={2} mb={2}>
                        <Button
                            variant="contained"
                            onClick={() => download_masked_document(verifyOTPData?.data?.document_pdf)}
                            color="secondary"
                            sx={{
                                mr: 3,
                                color: 'white',
                                backgroundColor: 'green',
                                '&:hover': {
                                    backgroundColor: 'darkgreen',
                                },
                            }}
                        >
                            Download PDF
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleCloseModal}
                            color="secondary"
                            sx={{ padding: '5px 10px', boxShadow: 2 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>

            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Aadhaar eKYC Verification"
                        subheader="Aadhaar eKYC(or OKYC) verifies and fetches the full aadhaar details through an Aadhaar linked Mobile and OTP-based verification."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Aadhaar Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Aadhaar Number"
                                    size="small"
                                    type="text"
                                    name="aadhaar_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    disabled={aadhaarDisabled}
                                    value={formik.values.aadhaar_number}
                                    required
                                />
                            </Grid>

                            {!otpStage && (
                                <Grid item>
                                    <Button
                                        fullWidth
                                        disabled={!formik.isValid || isGeneratingOTP}
                                        color="secondary"
                                        variant="outlined"
                                        type="submit"
                                    >
                                        {isGeneratingOTP ? <CircularProgress size={'1.5rem'} /> : 'Generate OTP'}
                                    </Button>
                                </Grid>
                            )}

                            {otpStage && (
                                <>
                                    <Grid item mt={2} mb={1}>
                                        <InputLabel>
                                            Enter the 6-digit OTP sent to your aadhaar linked phone number by UIDAI.
                                        </InputLabel>
                                        <TextField
                                            InputProps={{
                                                sx: {
                                                    display: 'flex',
                                                    gap: 0.5,
                                                    padding: 0.6,
                                                },
                                            }}
                                            placeholder="OTP"
                                            size="small"
                                            type="number"
                                            name="otp"
                                            onChange={(e) => setOtp(e.target.value)}
                                            onBlur={formik.handleBlur}
                                            required
                                        />
                                        <Grid item>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={handleResendOTP}
                                                disabled={resendDisabled}
                                            >
                                                {resendDisabled ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            color="secondary"
                                            variant="outlined"
                                            onClick={handleOtpSubmit}
                                            disabled={isVerifyingOTP || !otp}
                                        >
                                            {isVerifyingOTP ? <CircularProgress size="1.5rem" /> : 'Verify OTP'}
                                        </Button>
                                    </Grid>
                                </>
                            )}

                            {errorMessage && (
                                <Grid item>
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {errorMessage || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default AadhaarEkyc;
