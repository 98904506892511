import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useGetTransactionsQuery } from '../../../features/api/apiSlice';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const columns = [
    {
        id: 'order_id',
        label: 'Order ID',
        minWidth: 100,
        align: 'left',
    },
    {
        id: 'email',
        label: 'Transacion Email',
        minWidth: 100,
        align: 'left',
    },
    {
        id: 'created_at',
        label: 'Created At',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'amount',
        label: 'Amount',
        minWidth: 100,
        align: 'right',
        format: (value) => {
            return `₹ ${value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}`;
        },
    },
    {
        id: 'method',
        label: 'Method',
        minWidth: 100,
        align: 'right',
    },
];
function createData(order_id, email, created_at, amount, method) {
    method = method ? (method = capitalizeFirstLetter(method)) : 'None';
    created_at = new Date(created_at).toLocaleString('en-US', { hour12: true });
    return { order_id, email, created_at, amount, method, code: order_id };
}

export default function TransactionTable() {
    const [page, setPage] = React.useState(0);
    const { data } = useGetTransactionsQuery(page + 1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const [rows, setRows] = React.useState([]);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    React.useEffect(() => {
        const tmp_rows = [];
        data?.results?.forEach((el) => {
            tmp_rows.push(createData(el.order_id, el.user.email, el.created_at, el.amount, el.method));
        });
        setRows(tmp_rows);
        return () => {};
    }, [data]);

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: '80vw' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <TableRow tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={data ? data.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
