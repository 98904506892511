import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { usePanAdvancedMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const KycPanAdv = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getPanAdvData, { data, isLoading, isFetching, isError, isSuccess, error }] = usePanAdvancedMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        // validate: (values) => {
        //     return null;
        // },
        onSubmit: async (values) => {
            await getPanAdvData({ pan_number: values.pan_number, name: values.name }).unwrap();
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={2}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Pan Card Details &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Pan Number
                            </Grid>
                            <Grid item>{data?.data?.pan || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                First Name
                            </Grid>
                            <Grid item>{data?.data?.first_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Middle Name
                            </Grid>
                            <Grid item>{data?.data?.middle_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Last Name
                            </Grid>
                            <Grid item>{data?.data?.last_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Full Name
                            </Grid>
                            <Grid item>{data?.data?.full_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Status
                            </Grid>
                            <Grid item>{data?.data?.status || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Category
                            </Grid>
                            <Grid item>{data?.data?.category || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Last Updated
                            </Grid>
                            <Grid item>{data?.data?.last_updated || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Aadhaar Seeding Status
                            </Grid>
                            <Grid item>{data?.data?.aadhaar_seeding_status === 'Y' ? 'Yes' : 'No' || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Pan Name Cleaned
                            </Grid>
                            <Grid item>{data?.data?.name_information?.pan_name_cleaned || '-'}</Grid>
                        </Grid>
                        {data?.data?.name_information?.name_provided && (
                            <>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Name Provided
                                    </Grid>
                                    <Grid item>{data?.data?.name_information?.name_provided || '-'}</Grid>
                                </Grid>
                            </>
                        )}
                        {data?.data?.name_information?.match_score && (
                            <>
                                <Divider sx={{ width: '100%' }} flexItem />
                                <Grid container flexDirection="row" my={0.5}>
                                    <Grid item color="gray" sm={6}>
                                        Name Match Score
                                    </Grid>
                                    <Grid item>{data?.data?.name_information?.match_score || '-'}</Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="PAN Advanced Verification"
                        subheader="The Advance Verification API provides additional details compared to PAN Basic verification."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Pan Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Pan Number"
                                    size="small"
                                    type="text"
                                    name="pan_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Name(optional)</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Name"
                                    size="small"
                                    type="text"
                                    name="name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default KycPanAdv;
