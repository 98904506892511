import { useState, useEffect, useCallback } from 'react';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { Outlet, useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';

const TABS = [
    {
        value: 'pan-plus',
        label: 'Pan Plus verification',
        icon: (
            <div style={{ position: 'relative', width: 24, height: 24 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path
                        fill="currentColor"
                        d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
                    />
                </svg>
            </div>
        ),
    },
    {
        value: 'batch-pan-plus',
        label: 'Batch',
        icon: <Iconify icon="mdi:file-document-outline" width={24} />,
    },
];

export default function PanAccount() {
    const [activeTab, setActiveTab] = useState('pan-plus');
    const [lastParam, setLastParam] = useState('');
    const navigate = useNavigate();

    const handleChangeTab = useCallback(
        (newValue) => {
            navigate(`/dashboard/verification/pan-account/${newValue}`);
            setActiveTab(newValue);
        },
        [navigate]
    );

    useEffect(() => {
        const currentURL = window.location.href;
        const params = currentURL.split('/');
        const lastParamValue = params[params.length - 1];
        setLastParam(lastParamValue);
        setActiveTab(lastParamValue);
    }, [activeTab, navigate]);

    return (
        <Container
            sx={{
                maxWidth: {
                    md: '100%',
                },
            }}
        >
            <Card
                sx={{
                    mb: 3,
                    height: 72,
                }}
            >
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    sx={{
                        width: 1,
                        left: 0,
                        zIndex: 9,
                        pl: 2,
                        bgcolor: 'background.paper',
                        [`& .${tabsClasses.flexContainer}`]: {
                            pr: { md: 3 },
                            justifyContent: {
                                md: 'flex-start',
                            },
                            alignContent: {
                                md: 'center',
                            },
                        },
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#0E46A3',
                        },
                    }}
                >
                    {TABS.map((tab) => (
                        <Tab
                            key={tab.value}
                            value={tab.value}
                            icon={tab.icon}
                            label={tab.label}
                            onClick={() => handleChangeTab(tab.value)} // Call handleChangeTab on click
                            sx={{
                                flexDirection: 'row',
                                gap: '12px',
                                color: '#637381',
                                '&.Mui-selected': {
                                    color: '#0E46A3',
                                    fontWeight: 'bold',
                                },
                                '&.Mui-focusVisible': {
                                    backgroundColor: 'black',
                                },
                                '&:hover': {
                                    color: '#0E46A3',
                                    opacity: 1,
                                },
                            }}
                        />
                    ))}
                </Tabs>
            </Card>

            <Outlet />
        </Container>
    );
}
