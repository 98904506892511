import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
    // Planned to change like app: { dateRange....} later, see logs below for refrence.
    dateRange: {
        since_date: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        until_date: moment().format('YYYY-MM-DD'),
    },
    products: 'All',
    logs: {
        dateRange: {
            since_date: moment().subtract(7, 'd').format('YYYY-MM-DD'),
            until_date: moment().format('YYYY-MM-DD'),
        },
        products: 'All',
    },
    reports: {
        dateRange: {
            since_date: moment().subtract(7, 'd').format('YYYY-MM-DD'),
            until_date: moment().format('YYYY-MM-DD'),
        },
    },
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setLogsDateRange: (state, actions) => {
            state.logs.dateRange = actions.payload;
        },
        setLogsProducts: (state, actions) => {
            state.logs.products = actions.payload;
        },
        setReportsDateRange: (state, actions) => {
            state.reports.dateRange = actions.payload;
        },
    },
});

export const { setDateRange, setProducts, setLogsDateRange, setLogsProducts, setReportsDateRange } =
    dashboardSlice.actions;

export default dashboardSlice.reducer;
