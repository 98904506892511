import React from 'react';
import { Card, CardContent, CardActions, Button, Typography, Box, Stack, Link } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

const excludeGST = (price, gstRate) => {
    const gstAmount = (price * gstRate) / (100 + gstRate);
    const res = price - gstAmount;
    return res.toFixed(2);
};

export default function ProductCard({ data }) {
    return (
        <Card
            sx={{
                width: '300px',
                height: '420px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'relative',
            }}
        >
            <Box padding={5} flexGrow={1}>
                <Box paddingTop={4}>
                    <Stack sx={{ textAlign: 'left', paddingLeft: '10%', maxWidth: '300px' }} gap={2}>
                        {data.icon}
                        <Typography sx={{ fontWeight: 600, fontSize: '18px', lineHeight: 1.5 }} component="div">
                            {data.name}
                        </Typography>
                        <Typography
                            sx={{ fontWeight: 500, fontSize: '16px', lineHeight: 1.5, height: '100px' }}
                            variant="body2"
                            color="text.secondary"
                        >
                            {data.description}
                        </Typography>
                        <Link
                            href={data.learnMore}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: '#7743DB',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: 1.5,
                            }}
                        >
                            View API Docs
                            <ArrowForward style={{ transform: 'rotate(-45deg)', marginLeft: '4px' }} />
                        </Link>
                    </Stack>
                </Box>
            </Box>
            <Box display="flex" justifyContent="center" paddingBottom={4}>
                <Button
                    sx={{ width: '200px', height: '40px', backgroundColor: '#7743DB' }}
                    size="medium"
                    variant="contained"
                    color="primary"
                    href={data.explore}
                >
                    Try Now
                </Button>
            </Box>
            <Box
                sx={{
                    right: 1,
                    top: '0',
                    position: 'absolute',
                    border: '0.5px solid #FFEC9E',
                    borderBottomLeftRadius: 10,
                    borderTopRightRadius: 5,
                    padding: '5px',
                    backgroundColor: '#FFEC9E',
                    fontWeight: 600,
                }}
            >
                <Typography sx={{ color: '#ED9455' }} variant="span">
                    ₹ {excludeGST(data.price, 18)}
                </Typography>
                <Typography variant="span" color="text.secondary">
                    / API
                </Typography>
            </Box>
        </Card>
    );
}
