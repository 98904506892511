import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { useGetOriginalityDataMutation } from '../features/api/deepApiSlice';
import { TextFieldStyles } from '../utils/cssStyles';
import { toBase64 } from '../utils/util';
import { validate_supported_file_formats } from '../utils/validate_supported_file_formats_ocr';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    pt: 3,
    px: 4,
    pb: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

const DocumentOcr = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getOriginalityData, { data, isLoading, isError, error }] = useGetOriginalityDataMutation();
    console.log(error);
    const formik = useFormik({
        initialValues: {
            is_back: false,
            document_type: 1,
        },
        enableReinitialize: true,
        validate: (values) => {
            return validate_supported_file_formats(values);
        },
        onSubmit: async (values) => {
            await getOriginalityData({
                document1: await toBase64(values.picture1),
                is_back: values.is_back,
                document_type: values.document_type,
            })
                .unwrap()
                .then(() => {
                    setModalOpen(true);
                })
                .catch((err) => {
                    if (err?.data?.message) setModalOpen(true);
                });
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Document Details &nbsp;{' '}
                        {isError ? (
                            <CloseIcon
                                sx={{ border: 'red 1px solid', borderRadius: '50%' }}
                                fontSize="small"
                                htmlColor="red"
                            />
                        ) : (
                            <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                        )}
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={4}>
                                Message
                            </Grid>
                            <Grid item>{data?.message || error?.data?.message || 'Something went wrong.'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Digitisation Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Document Authenticity Check"
                        subheader="Upload a document for assessment of its authenticity, and verify it's originality."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Upload Document</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: TextFieldStyles,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    type="file"
                                    name="picture1"
                                    onChange={(e) => {
                                        formik.setFieldValue('picture1', e.target.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.picture1 !== undefined}
                                    helperText={formik.errors.picture1}
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Document Type</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: { ...TextFieldStyles, padding: 0 },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    name="document_type"
                                    id="document_type"
                                    select
                                    required
                                    value={formik.values.document_type}
                                    onChange={formik.handleChange}
                                    error={formik.touched.document_type && Boolean(formik.errors.document_type)}
                                    helperText={formik.touched.document_type && formik.errors.document_type}
                                >
                                    {[
                                        { name: 'Aadhaar', id: 1 },
                                        { name: 'Pan Card', id: 2 },
                                        { name: 'Bank Cheque', id: 6 },
                                    ].map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item ml={0.2}>
                                <FormControlLabel
                                    control={<Checkbox checked={formik.values.is_back || false} />}
                                    label="Is Back"
                                    name="is_back"
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && !error?.data?.message && (
                                    <Alert severity="error">
                                        <AlertTitle>{'Something went wrong, please try again.'}</AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item>
                                <b style={{ color: 'red' }}>*</b>
                                <i>Supports jpeg, jpg, png and pdf formats.</i>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default DocumentOcr;
