import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetDrivingLicenseRequestIdMutation, useGetDrivingLicenseDataMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const delay = (time) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

const KycDrivingLicense = () => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [getDrivingLicenseRequestId, { isLoading, isError, error }] = useGetDrivingLicenseRequestIdMutation();
    const [
        getDrivingLicenseData,
        {
            data,
            isLoading: dataIsLoading,
            isFetching: dataIsFetching,
            isError: dataIsError,
            isSuccess: dataIsSuccess,
            error: dataError,
        },
    ] = useGetDrivingLicenseDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            setLoading(true);
            const requestData = await getDrivingLicenseRequestId({
                dl_number: values.dl_number,
                dob: values.dob,
            }).unwrap();
            const getData = await fetch_data(requestData.request_id);
            setLoading(false);
            if (getData?.status === 'completed') setModalOpen(true);
        },
    });
    const fetch_data = async (request_id) => {
        const getData = await getDrivingLicenseData({ request_id }).unwrap();
        if (getData?.status === 'in_progress') {
            await delay(2000);
            return fetch_data(request_id);
        }
        return getData;
    };
    useEffect(() => {
        console.log(data);
    }, [data]);

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={2}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Driving License Details &nbsp;{' '}
                        <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3} sx={{ overflowY: 'scroll', maxHeight: '70vh' }}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Name
                            </Grid>
                            <Grid item>{data?.result?.source_output?.name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                DL Number
                            </Grid>
                            <Grid item>{data?.result?.source_output?.id_number || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                DL Status
                            </Grid>
                            <Grid item>{data?.result?.source_output?.dl_status || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of Issue
                            </Grid>
                            <Grid item>{data?.result?.source_output?.date_of_issue || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of Birth
                            </Grid>
                            <Grid item>{data?.result?.source_output?.dob || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Gender
                            </Grid>
                            <Grid item>{data?.result?.source_output?.gender || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Issuing RTO Name
                            </Grid>
                            <Grid item>{data?.result?.source_output?.issuing_rto_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Valid from
                            </Grid>
                            <Grid item>{data?.result?.source_output?.nt_validity_from || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Valid to
                            </Grid>
                            <Grid item>{data?.result?.source_output?.nt_validity_to || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Valid from (Transport)
                            </Grid>
                            <Grid item>{data?.result?.source_output?.t_validity_from || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Valid to (Transport)
                            </Grid>
                            <Grid item>{data?.result?.source_output?.t_validity_to || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Hazardous Valid till
                            </Grid>
                            <Grid item>{data?.result?.source_output?.hazardous_valid_till || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Hill Valid till
                            </Grid>
                            <Grid item>{data?.result?.source_output?.hill_valid_till || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Relatives Name
                            </Grid>
                            <Grid item>{data?.result?.source_output?.relatives_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Source
                            </Grid>
                            <Grid item>{data?.result?.source_output?.source || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Status
                            </Grid>
                            <Grid item>{data?.result?.source_output?.status || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Address
                            </Grid>
                            <Grid item>{data?.result?.source_output?.address || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of last transaction
                            </Grid>
                            <Grid item>{data?.result?.source_output?.date_of_last_transaction || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Last Transacted at
                            </Grid>
                            <Grid item>{data?.result?.source_output?.last_transacted_at || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Card Serial Number
                            </Grid>
                            <Grid item>{data?.result?.source_output?.card_serial_no || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Badge Details
                            </Grid>
                            <Grid item>{data?.result?.source_output?.badge_details || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Face Image
                            </Grid>
                            <Grid item>{data?.result?.source_output?.face_image || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Class of Vehicles (COV) Details
                            </Grid>
                            <Grid item color="gray" sm={6}>
                                {Array.isArray(data?.result?.source_output?.cov_details) &&
                                    data?.result?.source_output?.cov_details?.map((res) => {
                                        return (
                                            <>
                                                <Grid container flexDirection="row" my={0.5}>
                                                    <Grid item color="gray" sm={6}>
                                                        Category
                                                    </Grid>
                                                    <Grid item>{res.category || '-'}</Grid>
                                                </Grid>
                                                <Divider light sx={{ width: '100%' }} flexItem />
                                                <Grid container flexDirection="row" my={0.5}>
                                                    <Grid item color="gray" sm={6}>
                                                        COV
                                                    </Grid>
                                                    <Grid item>{res.cov || '-'}</Grid>
                                                </Grid>
                                                <Divider light sx={{ width: '100%' }} flexItem />
                                                <Grid container flexDirection="row" my={0.5}>
                                                    <Grid item color="gray" sm={6}>
                                                        Issue Date
                                                    </Grid>
                                                    <Grid item>{res.issue_date || '-'}</Grid>
                                                </Grid>
                                                <Divider sx={{ width: '100%' }} flexItem />
                                            </>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Driving License Verification"
                        subheader="The Driving License Verification API instantly verifies details of a driving license by confirming them from the Government database."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Driving License Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Driving License Number"
                                    size="small"
                                    type="text"
                                    name="dl_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Date of Birth</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="YYYY-MM-DD"
                                    size="small"
                                    type="text"
                                    name="dob"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {(data?.status === 'failed' || isError) && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message ||
                                                error?.data?.detail ||
                                                'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default KycDrivingLicense;
