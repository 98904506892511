import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
    DataGrid,
    GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    getGridStringOperators,
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Pagination from '@mui/material/Pagination';
import { Card, CardHeader, Grid, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router';
import { useGetProductsQuery, useGetUsageQuery } from '../features/api/apiSlice';
import CustomNoRowsOverlay from '../sections/@dashboard/logs/NoRowOverlay';
import EnhancedTableToolbar from '../sections/@dashboard/logs/LogsTableToolbar';
import LogsFilters from '../sections/@dashboard/logs/LogsFilters';

const filterOperators = getGridStringOperators().filter(({ value }) => ['equals'].includes(value));
const columns = [
    {
        field: 'transaction_id',
        headerName: 'Transaction Id',
        filterable: false,
        flex: 2,
        renderCell: ({ value }) => {
            return (
                <Tooltip title="Click to copy">
                    <Box
                        onClick={() => {
                            navigator.clipboard.writeText(value);
                        }}
                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'pointer' }}
                    >
                        {value}
                    </Box>
                </Tooltip>
            );
        },
    },
    {
        field: 'product_id',
        headerName: 'Product',
        filterable: false,
        sortable: true,
        flex: 1,
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        type: 'date',
        editable: false,
        sortable: true,
        filterable: false,
        flex: 1,
        renderCell: ({ value }) => {
            return new Date(value).toLocaleString('en-US', { hour12: true });
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        type: 'number',
        filterOperators,
        sortable: true,
        editable: false,
        flex: 0,
    },
    {
        field: 'message',
        headerName: 'Message',
        sortable: true,
        editable: false,
        flex: 2,
        renderCell: (params) => {
            return params.value;
        },
    },
];

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
        <Pagination
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

export default function LogsTable() {
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({});
    useEffect(() => {
        setPage(1);
        return () => {};
    }, [filters]);

    const { dateRange, products } = useSelector((state) => state.dashboard.logs);
    const { data, isFetching } = useGetUsageQuery({
        page,
        until_date: dateRange.until_date,
        since_date: dateRange.since_date,
        products,
        filters,
    });
    const { data: allProducts, isError: productsError, isLoading: productsIsLoading } = useGetProductsQuery();
    const navigate = useNavigate(); 
    return (
        <Box mx={1}>
            <Grid sx={{ mb: 5 }}>
                <CardHeader title="API Logs" subheader="Here are logs of your API usage" sx={{ padding: 0 }} />
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <LogsFilters allProducts={allProducts?.productList || []} />
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: '#2065D1' }}
                    onClick={() => {navigate('/dashboard/logs/api-response-time');}}
                >
                    API Response-Time Metrics
                </Button>
            </div>

            <Card sx={{ mb: 2, boxShadow: 10 }}>
                <EnhancedTableToolbar setFilters={setFilters} filters={filters} />
                <Box sx={{ height: '500px' }}>
                    <DataGrid
                        disableColumnFilter
                        pagination
                        pageSize={100}
                        onPageChange={(e) => {
                            setPage(e + 1);
                        }}
                        paginationMode="server"
                        rowCount={data ? data.count : 0}
                        columns={columns}
                        rows={data?.results ? data.results : []}
                        components={{
                            Toolbar: GridToolbar,
                            Pagination: CustomPagination,
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        getRowId={(row) => {
                            return row.transaction_id;
                        }}
                        loading={isFetching}
                    />
                </Box>
            </Card>
        </Box>
    );
}
