import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { Box, Button, Grid, InputLabel, MenuItem, OutlinedInput, Select, CardHeader } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { AppApiUsage } from '../sections/@dashboard/app';
import {
    setLogsDateRange as setDateRange,
    setLogsProducts as setProducts,
} from '../features/dashboard/dashboardSlice';
import { useGetResponseTimeDataMutation, useGetProductsQuery } from '../features/api/apiSlice';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

const RenderDatePresets = () => {
    const data = [];
    return (
        <Grid flexDirection={'row'} p={2}>
            {data.map((val) => (
                <Button variant="outlined" key={val.name}>
                    {val.name}
                </Button>
            ))}
        </Grid>
    );
};

export default function ApiResponseTime() {
    const theme = useTheme();
    const [getResponseTimeData, {
        data: usageData,
        isLoading: responseTimeDataLoading,
    }] = useGetResponseTimeDataMutation();
    const { data: allProducts } = useGetProductsQuery();
    const { isLoading: productsLoading } = useGetProductsQuery();
    const [ title, setTitle ] = useState("");
    const [dateRangeSelectorFocused, setDateRangeSelectorFocused] = useState(null);
    const dispatch = useDispatch();

    const { dateRange, products } = useSelector((state) => state.dashboard.logs);

    const generateChart = async () => {
        setTitle(`Average Response time for ${products} Service`);
        getResponseTimeData({
            product: products,
            since_date: dateRange.since_date,
            until_date: dateRange.until_date,
        });
    }

    const handleChange = async (event) => {
        const {
            target: { value },
        } = event;
        dispatch(setProducts(value));
    };
    const onDatesChange = async ({ startDate, endDate }) => {
        dispatch(
            setDateRange({
                until_date: endDate != null ? endDate.format('YYYY-MM-DD') : null,
                since_date: startDate != null ? startDate.format('YYYY-MM-DD') : null,
            })
        );
    };
    const onFocusChange = (focusedInput) => {
        setDateRangeSelectorFocused(focusedInput);
    };

    useEffect(() => {
        dispatch(setProducts('Bank-Account'));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        generateChart();
    }, [dateRange, products]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box mx={1}>
            <Grid sx={{ mb: 5 }}>
                <CardHeader title="API Response-Time Metrics" subheader="Here is the metric for the API Response Time" sx={{ padding: 0 }} />
            </Grid>
            <Grid container item xs={12} mb={2} width={'100%'} spacing={2} alignItems="center">
                <Grid item>
                    <InputLabel className="date-range-label">Date Range</InputLabel>
                    <DateRangePicker
                        renderCalendarInfo={ RenderDatePresets }
                        onDatesChange={ onDatesChange }
                        onFocusChange={ onFocusChange }
                        focusedInput={ dateRangeSelectorFocused }
                        startDate={ moment(dateRange.since_date, 'YYYY-MM-DD') }
                        endDate={ dateRange.until_date === null ? null : moment(dateRange.until_date, 'YYYY-MM-DD') }
                        isOutsideRange={() => false}
                        startDateId="Na"
                        endDateId="Na"
                        minimumNights={0}
                    />
                </Grid>
                <Grid item width={300}>
                    <InputLabel className="products-label">Products</InputLabel>
                    <Select
                        input={<OutlinedInput size="small" />}
                        size="small"
                        value={ products }
                        fullWidth
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        renderValue={(selected) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 0.5,
                                    padding: 0.6,
                                }}
                            >
                                {selected}
                            </Box>
                        )}
                    >
                        {(responseTimeDataLoading || productsLoading) ?
                            <CircularProgress size={'1.5rem'} /> :
                            allProducts?.productList.map((name) => {
                                return (
                                    <MenuItem key={name[0]} value={name[0]} style={getStyles(name[0], products, theme)}>
                                        {name[0]}
                                    </MenuItem>
                                );
                            }
                        )}
                    </Select>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} marginTop={3}>
                { (responseTimeDataLoading || productsLoading) ? 
                    <CircularProgress size={'1.5rem'} /> :
                    <AppApiUsage
                        title={title}
                        chartLabels={usageData ? usageData.chartLabels : []}
                        chartData={usageData ? usageData.chartData : []}
                        stroke_curve='straight'
                        y_axis_formatter={{
                            formatter: (y) => {
                                if (typeof y !== 'undefined') {
                                    return `${y} seconds`;
                                }
                                return y;
                            },
                        }}
                    />
                }
            </Grid>
        </Box>
    );
}
