import React, { useState } from 'react';
import { Button, Box, Grid, InputLabel, TextField, Alert, AlertTitle } from '@mui/material';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import { setReportsDateRange as setDateRange } from '../../../features/dashboard/dashboardSlice';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../theme/css/react_dates_overrides.css.css';
import { useGenerateReportMutation } from '../../../features/api/apiSlice';

const RenderDatePresets = () => {
    const data = [];
    return (
        <Grid flexDirection={'row'} p={2}>
            {data.map((val) => (
                <Button variant="outlined" key={val.name}>
                    {val.name}
                </Button>
            ))}
        </Grid>
    );
};

export default function AppFilters() {
    const { dateRange } = useSelector((state) => state.dashboard.reports);
    const dispatch = useDispatch();
    const [dateRangeSelectorFocused, setDateRangeSelectorFocused] = useState(null);
    const [generateReport, { isLoading, isError, isSuccess, error }] = useGenerateReportMutation();
    console.log(error);
    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            generateReport({
                since_date: dateRange.since_date,
                until_date: dateRange.until_date,
                email: values.email,
            });
        },
    });
    const onDatesChange = ({ startDate, endDate }) => {
        dispatch(
            setDateRange({
                until_date: endDate != null ? endDate.format('YYYY-MM-DD') : null,
                since_date: startDate != null ? startDate.format('YYYY-MM-DD') : null,
            })
        );
    };

    const onFocusChange = (focusedInput) => {
        setDateRangeSelectorFocused(focusedInput);
    };

    return (
        <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
            <Grid
                container
                item
                xs={12}
                mb={2}
                width={'100vw'}
                spacing={2}
                alignItems="flex-start"
                flexDirection="column"
            >
                <Grid item>
                    <InputLabel className="date-range-label">Date Range</InputLabel>
                    <DateRangePicker
                        renderCalendarInfo={RenderDatePresets}
                        onDatesChange={onDatesChange}
                        onFocusChange={onFocusChange}
                        focusedInput={dateRangeSelectorFocused}
                        startDate={moment(dateRange.since_date, 'YYYY-MM-DD')}
                        endDate={dateRange.until_date === null ? null : moment(dateRange.until_date, 'YYYY-MM-DD')}
                        isOutsideRange={() => false}
                        startDateId="Na"
                        endDateId="Na"
                        minimumNights={0}
                        appendToBody
                    />
                </Grid>
                <Grid item>
                    <InputLabel>Format</InputLabel>
                    <TextField
                        defaultValue="csv"
                        InputProps={{
                            shrink: 'true',
                            sx: {
                                display: 'flex',
                                gap: 0.5,
                                padding: 0.6,
                            },
                        }}
                        size="small"
                        type="text"
                        disabled
                    />
                </Grid>
                <Grid item>
                    <InputLabel>Email</InputLabel>
                    <TextField
                        InputProps={{
                            sx: {
                                display: 'flex',
                                gap: 0.5,
                                padding: 0.6,
                            },
                        }}
                        placeholder="Send report to Email"
                        size="small"
                        type="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                    />
                </Grid>
                <Grid item sm={6} mt={2}>
                    {isSuccess && (
                        <Alert severity="success">
                            <AlertTitle>Email sent successfully.</AlertTitle>
                        </Alert>
                    )}
                    {isError && error.status !== 504 && (
                        <Alert severity="error">
                            <AlertTitle>Something went wrong, please try again.</AlertTitle>
                        </Alert>
                    )}
                    {isError && error.status === 504 && (
                        <Alert severity="warning">
                            <AlertTitle>
                                Please check your mail again after some time because it appears to be taking longer than
                                usual.
                            </AlertTitle>
                        </Alert>
                    )}
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        disabled={!formik.isValid || isLoading}
                        color="secondary"
                        variant="outlined"
                        type="submit"
                    >
                        {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Send Report'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
