import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton, InputBase, Snackbar, Alert } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from 'react-router-dom';
import Logo from '../components/logo';
import succesImg from '../assets/illustrations/SuccessCheck.png'

const useQuery = () => new URLSearchParams(useLocation().search);

const DigilockerLandingPage = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const query = useQuery();
    const code = query.get('code') || 'No code found';

    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(code);
        setSnackbarOpen(true);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ background: 'linear-gradient(135deg, #dde5eb, #ccd4dc)', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: '2px' }}>

            <Box sx={{
                maxWidth: '640px',
                width: '100%',
                textAlign: 'center',
                padding: '20px',
                borderRadius: '32px',
                backgroundColor: 'white',
                boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
                mt: 'auto',
                mb: 'auto',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px 24px',
                    mb: 2
                }}>

                    <Box sx={{ display: 'inline-flex' }}>
                        <Logo />
                    </Box>
                    <Typography variant="h6" fontWeight="bold" color={'#CAD6E1'}>
                        DIGILOCKER
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                    <Box
                        component="img"
                        src={succesImg}
                        alt="Success"
                        sx={{ width: 100, height: 100 }}
                    />
                </Box>


                <Typography variant="h4" fontWeight="bold" gutterBottom>
                    DigiLocker Session Generated
                </Typography>
                <Typography variant="body1" sx={{ mb: 8 }}>
                    Please copy the DigiLocker code mentioned below and paste it in your dashboard to fetch the Aadhaar Details.
                </Typography>

                <Paper sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    padding: '4px',
                    borderRadius: '8px',
                    mb: 1
                }}>
                    <Typography fontWeight="bold">
                        DigiLocker Code-
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', }}>
                        <InputBase
                            value={code}
                            readOnly
                            sx={{ flex: 1, fontSize: '14px', fontWeight: 'medium', padding: '2px', marginBottom: '4px' }}
                        />
                        <IconButton color='primary' onClick={copyCodeToClipboard} sx={{ ml: 1 }}>

                            <Typography level="body-sm" mr={1.5}>
                                Copy
                            </Typography>
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>
                </Paper>

            </Box>

            <Box sx={{
                width: '100%',
                position: 'fixed',
                bottom: 0,
                backgroundColor: '#f1f1f1',
                padding: '16px 24px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Typography variant="body2" sx={{ mb: 1, fontWeight: 'medium' }}>
                        Business & Partnerships:
                        <Typography
                            component="a"
                            href="mailto:support@deepvue.tech"
                            sx={{
                                textDecoration: 'underline',
                                color: 'rgba(0, 0, 0, 0.8)',
                                ml: 0.5,
                            }}>
                            support@deepvue.tech
                        </Typography>
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                        Business & Payment-related Queries:
                        <Typography
                            component="a"
                            href="mailto:support@deepvue.tech"
                            sx={{
                                textDecoration: 'underline',
                                color: 'rgba(0, 0, 0, 0.8)',
                                ml: 0.5,
                            }}>
                            support@deepvue.tech
                        </Typography>
                    </Typography>
                </Box>

                <Typography sx={{ fontSize: '14px', color: 'grey', mr: 6 }}>
                    Copyright © 2024 Deepvue.tech. All Rights Reserved.
                </Typography>
            </Box>

            <Snackbar open={snackbarOpen}
                onClose={handleCloseSnackbar}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Copied Successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default DigilockerLandingPage;
