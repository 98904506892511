import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import _ from "lodash";
import { usePanPlusMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const DisplayResultRow = ({dic_key, dic_value}) => {
    if(dic_value === true || dic_value === false){
        dic_value = dic_value ? "Yes": "No";
    }
    if(typeof dic_value !== "string" || dic_value === ""){
        dic_value = "-";
    }
    return (
        <Grid container flexDirection="row" my={0.5}>
            <Grid item color="gray" sm={6}>
                {dic_key}
            </Grid>
            <Grid item>{dic_value? dic_value === ""?'-':dic_value : '-'}</Grid>
        </Grid>
    );
}

const KycPanPlus = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getRcAdvData, { data, isLoading, isError, error }] = usePanPlusMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        // validate: (values) => {
        //     return null;
        // },
        onSubmit: async (values) => {
            await getRcAdvData({ pan_number: values.pan_number }).unwrap();
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={2}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Pan Plus Details &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3} sx={{ overflowY: 'scroll', maxHeight: '70vh' }}>
                        {Object.entries(data?.data || {}).map(([key]) => {
                            switch(key){
                                case "address":
                                    return (
                                        <DisplayResultRow
                                            key={key}
                                            dic_key={_.startCase(key)}
                                            dic_value={data?.data[key]["full"]}
                                        />
                                    );
                                case "full_name_split":
                                    return null;
                                case "category":
                                    return (
                                        <DisplayResultRow
                                            key={key}
                                            dic_key={_.startCase(key)}
                                            dic_value={_.upperFirst(data?.data[key])}
                                        />
                                    );
                                default:
                                    return (
                                        <DisplayResultRow
                                            key={key}
                                            dic_key={_.startCase(key)}
                                            dic_value={data?.data[key]}
                                        />
                                    );
                            }
                        })}
                        <Divider sx={{ width: '100%' }} flexItem />
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Pan Plus Verification"
                        subheader="Get comprehensive information related to an individual's PAN including name, DOB, address, contact information, and PAN card status."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Pan Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Pan Number"
                                    size="small"
                                    type="text"
                                    name="pan_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message || error?.data?.detail || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default KycPanPlus;
