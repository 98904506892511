import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetChequDataMutation, useGetChequeLogsQuery } from '../../features/api/backOfficeApiSlice';
import Modal from './Modal';
import '../../theme/css/fileInput.css';

const toCamelCase = (name) => {
    name = name.replace('_', ' ');
    return name.charAt(0).toUpperCase() + name.slice(1);
};

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result.split(',')[1]);
        };
        reader.onerror = (error) => reject(error);
    });

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const columns = [
    {
        field: 'created_at',
        headerName: 'Timestamp',
        type: 'date',
        editable: false,
        sortable: true,
        filterable: false,
        flex: 2,
        renderCell: ({ value }) => {
            return new Date(value).toLocaleString('en-US', { hour12: true });
        },
    },
    {
        field: 'bank_name',
        headerName: 'Bank Name',
        sortable: true,
        editable: false,
        flex: 2,
    },
    {
        field: 'account_number',
        headerName: 'Account Number',
        sortable: true,
        editable: false,
        flex: 2,
        renderCell: ({ value }) => {
            return (
                <Tooltip title="Click to copy">
                    <Box
                        onClick={() => {
                            navigator.clipboard.writeText(value);
                        }}
                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'pointer' }}
                    >
                        {value}
                    </Box>
                </Tooltip>
            );
        },
    },
    {
        field: 'ifsc_code',
        headerName: 'IFSC Code',
        sortable: true,
        editable: false,
        flex: 1,
        renderCell: ({ value }) => {
            return (
                <Tooltip title="Click to copy">
                    <Box
                        onClick={() => {
                            navigator.clipboard.writeText(value);
                        }}
                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'pointer' }}
                    >
                        {value}
                    </Box>
                </Tooltip>
            );
        },
    },
    {
        field: 'account_holder_name',
        headerName: 'A/c Holder Name',
        sortable: true,
        editable: false,
        flex: 2,
    },
];

const Cheque = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [picData, setPicData] = useState(null);
    const [getPanData, { data, isLoading, isFetching, isError, isSuccess, error }] = useGetChequDataMutation();
    console.log(toCamelCase('account_no'));
    const {
        data: logData,
        isLoading: logsIsLoading,
        isFetching: logsIsFetching,
        isError: logsIsError,
        isSuccess: logsIsSuccess,
        error: logsError,
    } = useGetChequeLogsQuery({ page });
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validate: (values) => {
            return null;
        },
        onSubmit: async (values) => {
            await getPanData({ document1: picData }).unwrap();
            console.log(data);
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                setOpen={setModalOpen}
                children={
                    <Box sx={{ ...style, width: 500 }}>
                        <CheckCircleOutlineTwoToneIcon
                            sx={{ margin: 'auto', display: 'block', fontSize: '4rem' }}
                            htmlColor="green"
                        />
                        <Box sx={{ margin: 'auto', display: 'block', width: 'fit-content' }}>
                            Bank Cheque Processed Successfully
                        </Box>
                        <br />
                        {data?.data?.bank_name && (
                            <>
                                <b> {toCamelCase('bank_name')}:</b> {data.data.bank_name}
                                <br />
                            </>
                        )}
                        {data?.data?.account_no && (
                            <>
                                <b> {toCamelCase('account_no')}:</b> {data.data.account_no}
                                <br />
                            </>
                        )}
                        {data?.data?.ifsc_code && (
                            <>
                                <b> {toCamelCase('ifsc_code')}:</b> {data.data.ifsc_code}
                                <br />
                            </>
                        )}
                        {data?.data?.account_name && (
                            <>
                                <b> {toCamelCase('bank_A/c holder name')}:</b> {data.data.account_name}
                                <br />
                            </>
                        )}
                        {data?.data?.bank_address && (
                            <>
                                <b> {toCamelCase('bank_address')}:</b> {data.data.bank_address}
                                <br />
                            </>
                        )}
                        {data?.data?.micr_cheque_number && (
                            <>
                                <b> {toCamelCase('cheque number')}:</b> {data.data.micr_cheque_number}
                                <br />
                            </>
                        )}
                        {data?.data?.micr_code && (
                            <>
                                <b> {toCamelCase('micr_code')}:</b> {data.data.micr_code}
                                <br />
                            </>
                        )}
                    </Box>
                }
            />
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Back Office
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Cheque Verification"
                        subheader="Verify Bank Cheque details in real time to onboard legitimate and successful customers."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Upload Cheque</InputLabel>
                                <input
                                    required
                                    name="picture"
                                    // accept="image/*"
                                    type="file"
                                    onChange={async (e) => {
                                        console.log(await toBase64(e.target.files[0]));
                                        setPicData(await toBase64(e.target.files[0]));
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Card sx={{ mb: 2, mt: 5, boxShadow: 10 }}>
                <Box sx={{ height: '500px' }}>
                    <DataGrid
                        disableColumnFilter
                        pagination
                        pageSize={100}
                        onPageChange={(e) => {
                            setPage(e + 1);
                        }}
                        paginationMode="server"
                        rowCount={logData ? logData.count : 0}
                        columns={columns}
                        rows={logData?.results ? logData.results : []}
                        // components={{
                        //     Toolbar: GridToolbar,
                        //     Pagination: CustomPagination,
                        //     LoadingOverlay: LinearProgress,
                        //     NoRowsOverlay: CustomNoRowsOverlay,
                        // }}
                        // getRowId={(row) => {
                        //     return row.transactionid;
                        // }}
                        loading={logsIsFetching}
                    />
                </Box>
            </Card>
        </>
    );
};

export default Cheque;
