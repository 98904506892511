import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const Balance = () => {
    const { user } = useSelector((state) => {
        return state.auth.auth;
    });
    return (
        <>
            <Grid>
                <Typography variant="p" color="gray">
                    Current Balance
                </Typography>
                <Typography fontFamily="sans-serif" variant="h2">
                    ₹{' '}
                    {user?.client
                        ? user.client.wallet_balance.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : 0}
                </Typography>
            </Grid>
        </>
    );
};

export default Balance;
