import React, { useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import {
    createTheme,
    Container,
    CssBaseline,
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    Divider,
    Alert,
    AlertTitle,
} from '@mui/material';
import Link from '@mui/material/Link';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axiosPublic';
import { useResetPasswordMutation } from '../features/api/publicApiSlice';

const theme = createTheme();

const ResetPassword = (props) => {
    const navigate = useNavigate();
    const [alert, setAlert] = useState(undefined);
    const [resetPassword] = useResetPasswordMutation();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setAlert(undefined);
        const data = new FormData(event.currentTarget);
        resetPassword({ email: data.get('email') })
            .unwrap()
            .then((res) => {
                setAlert({
                    title: undefined,
                    message: 'Email sent successfully.',
                    severity: 'success',
                });
            })
            .catch((err) => {
                setAlert({
                    title: undefined,
                    message: 'Something went wrong.',
                    severity: 'error',
                });
            });
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                }}
            >
                <Typography component="h1" variant="h5" sx={{ marginRight: 'auto', marginBottom: 1 }}>
                    Forgot/Reset Password
                </Typography>
                <Paper
                    sx={{
                        paddingTop: 3,
                        paddingRight: 10,
                        paddingBottom: 3,
                        paddingLeft: 3,
                        mt: 2,
                    }}
                >
                    {alert && (
                        <Alert severity={alert.severity}>
                            {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
                            {alert.message}
                        </Alert>
                    )}
                    <Typography>
                        Lost your password or Reset Password? Please enter your username or email address. You will
                        receive a link to create a new password via email.
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, mb: 3 }}>
                        <TextField
                            margin="normal"
                            required
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            fullWidth
                            type="email"
                        />
                        <Button type="submit" variant="outlined" sx={{ mt: 3, mb: 2 }}>
                            Reset Password
                        </Button>
                    </Box>
                    <Divider />
                    <Grid container>
                        <Grid item sm sx={{ mt: 1, fontSize: 15 }}>
                            <Link
                                href="/login"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/login');
                                }}
                                variant="inherit"
                            >
                                Remember password?
                            </Link>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
};

export default ResetPassword;
